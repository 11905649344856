export const loadPointsHeaders = [
  'POINT',
  'SPEED',
  'TORQUE',
  'CDM LOSSES',
  'MOTOR LOSSES',
  'PDS ABSOLUTE LOSSES',
  'PDS RELATIVE LOSSES',
  'PDS EFFICIENCY',
];
export const loadPointsHeadersInReportPage = [
  'Point',
  'Speed',
  'Torque',
  'CDM Losses',
  'Motor Losses',
  'PDS Absolute Losses',
  'PDS Relative Losses',
  'PDS Efficiency',
];
export const standardPointsHeaders = [
  'POINT',
  'SPEED',
  'TORQUE',
  'ABSOLUTE LOSSES',
  'RELATIVE LOSSES',
  'EFFICIENCY',
];

export const driveStandardPointsHeader = [
  'POINT',
  'FREQUENCY',
  'CURRENT',
  'ABSOLUTE LOSSES',
  'RELATIVE LOSSES',
  'EFFICIENCY',
];

export const operatingPoints = [
  {
    name: 'Custom Points',
    value: 'customPoints',
  },
];

export const driveStandardPointsValues = [
  {
    frequency: 0,
    current: 25,
  },
  {
    frequency: 0,
    current: 50,
  },
  {
    frequency: 0,
    current: 100,
  },
  {
    frequency: 50,
    current: 25,
  },
  {
    frequency: 50,
    current: 50,
  },
  {
    frequency: 50,
    current: 100,
  },
  {
    frequency: 90,
    current: 50,
  },
  {
    frequency: 90,
    current: 100,
  },
];
