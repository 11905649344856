import { useDispatch, useSelector } from 'react-redux';
import CommonLoader from '../../../../components/Loader';
import { Industry } from '../../models/SelectionModel';
import { useGetIndustriesQuery } from '../../services/APISelectionService';
import { setIndustryType } from '../../store/WorkspaceReducer';
import { RootState } from '../../../../store/rootReducer';
import { GetCurrentCountry } from '../../../../utils/GeneralUtils';

interface InudstrySelectionProps {
  onIndustryCardClick: (data: string) => void;
}

const IndustrySelection = (props: InudstrySelectionProps) => {
  const { data: IndustryData, isLoading } = useGetIndustriesQuery('en-US');
  const dispatch = useDispatch();
  const countryCode = useSelector((state: RootState) => {
    return state.home.projectData.destinationCountry;
  });
  const currentCountryId = GetCurrentCountry().id ?? '';

  const handleIndustryClick = (industry: Industry) => {
    dispatch(
      setIndustryType({
        industryId: industry.id,
        countryCode: !!countryCode ? countryCode : currentCountryId,
        industryName: industry.name,
      })
    );
    props.onIndustryCardClick(industry.name);
  };

  if (isLoading) {
    return (
      <CommonLoader
        size="xlarge"
        indicatorType={'circular'}
        progressType={'indeterminate'}
        progressValue={50}
        type={'primary-black'}
        dataTestID="industry-loading-indicator"
      />
    );
  }

  return (
    <div>
      <span
        className="industry-selection-header"
        data-rf="industry-selection-header"
        data-testid="industry-selection-header"
      >
        Select your industry
      </span>
      <div className="industry-container" data-testid="industry-container">
        {IndustryData?.map((industry) => {
          return (
            <div
              key={industry.id}
              data-rf={industry.name + '-industry'}
              data-testid={'industrynames'}
              className="industry-details-container"
              onClick={() => handleIndustryClick(industry)}
              onKeyDown={() => {}}
              role="button"
            >
              <div className="industry-image-container">
                <img src={industry.icon?.url} alt={industry.title} />
              </div>

              <div className="industry-label-container">
                <span className="industry-label"> {industry.name}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IndustrySelection;
