import React, { useEffect, useRef } from 'react';
import '../styles/D3.scss';
import * as d3 from 'd3';

const SVG_DEFAULT_HEIGHT = 468;
const SVG_DEFAULT_WIDTH = 992;

// interface for D3 coordinates
export interface ICoordinatesD3 {
  x: number;
  y: number;
  index?: number;
  width?: number;
  height?: number;
  shape?: string;
  tooltipContent?: string;
  pointLabel?: string;
  pointLabelFontSize?: string;
  pointColor?: string;
  customShape?: string;
}

interface IChartData {
  coordinates: ICoordinatesD3[];
  id: string;
  xAxisLabel: string;
  yAxisLabel: string;
  xAxisTicks: number[];
  yAxisTicks: number[];
  darkGridLines?: {
    xAxis: number[];
    yAxis: number[];
  };
  lightGridLines?: {
    xAxis: number[];
    yAxis: number[];
  };
  height?: number;
  width?: number;
  isPDS?: boolean;
}

// Component to create D3 scatter chart
export const D3: React.FC<IChartData> = ({
  coordinates,
  xAxisLabel,
  yAxisLabel,
  xAxisTicks,
  yAxisTicks,
  darkGridLines,
  lightGridLines,
  width,
  height,
  isPDS,
  id,
}) => {
  //Get svg reference
  const svgRef = useRef(null);
  //Get the tooltip div
  const tooltip = d3.select(`.d3-tooltip`);

  useEffect(() => {
    //width for svg
    width = width ? width : SVG_DEFAULT_WIDTH;
    //height for svg
    height = height ? height : SVG_DEFAULT_HEIGHT;

    //remove exisitng chart before redraw
    d3.selectAll(`#${id} svg > *`).remove();

    //Create Responsive svg
    const svg = d3
      .select(svgRef.current)
      .attr('viewBox', `-50 0 ${width} ${height + 60}`)
      .attr('id', id);
    //   .style('overflow', 'visible');

    //X scale value from -10 to 110
    const xScale = d3
      .scaleLinear()
      .domain([xAxisTicks[0] - 10, xAxisTicks[xAxisTicks.length - 1] + 10])
      .range([xAxisTicks[0], width]);

    //Y scale value from 0 to 120
    const yScale = d3
      .scaleLinear()
      .domain([yAxisTicks[0], yAxisTicks[yAxisTicks.length - 1] + 25])
      .range([height, yAxisTicks[0]]);

    const xAxis = d3.axisBottom(xScale).tickValues(xAxisTicks);
    const yAxis = d3.axisLeft(yScale).tickValues(yAxisTicks);

    svg.append('g').call(xAxis).attr('transform', `translate(0,${height})`);

    svg.append('g').attr('class', 'hideLine').call(yAxis.tickSize(1));

    // Label for X axis
    svg
      .append('text')
      .style('text-anchor', 'middle')
      .attr('x', width / 2)
      .attr('y', height + 50)
      .text(xAxisLabel);

    // Label for Y axis
    svg
      .append('text')
      .attr('transform', 'rotate(270)')
      .style('text-anchor', 'middle')
      .attr('y', -30)
      .attr('x', -130)
      .text(yAxisLabel);

    // gridlines in x axis function
    function make_x_gridlines(values: any) {
      return d3.axisBottom(xScale).tickValues(values);
    }

    // gridlines in y axis function
    function make_y_gridlines2(values: any) {
      return d3.axisLeft(yScale).tickValues(values);
    }

    //Grid line with no points on x axis (Light grey Line)
    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', 'translate(0,' + height + ')')
      .call(
        make_x_gridlines(lightGridLines?.xAxis)
          .tickSize(-height)
          .tickFormat(() => ''),
      );

    // Grid line with points on Xaxis (Dark Line)
    svg
      .append('g')
      .attr('class', 'darkLine')
      .attr('transform', 'translate(0,' + height + ')')
      .call(
        make_x_gridlines(darkGridLines?.xAxis)
          .tickSize(-height)
          .tickFormat(() => ''),
      );

    //Grid line with no points on y axis (Light grey Line)
    svg
      .append('g')
      .attr('class', 'grid')
      .call(
        make_y_gridlines2(lightGridLines?.yAxis)
          .tickSize(-width)
          .tickFormat(() => ''),
      );

    // Grid line with points on Yaxis (Dark Line)
    svg
      .append('g')
      .attr('class', 'darkLine')
      .call(
        make_y_gridlines2(darkGridLines?.yAxis)
          .tickSize(-width)
          .tickFormat(() => ''),
      );

    //Group all elements to one
    const groups = svg
      .selectAll('.groups')
      .data(coordinates)
      .enter()
      .append('g')
      .attr('class', 'gbar');

    //Point symbol
    const dots = groups.append('path');

    const symbol = d3.symbol();

    // Different symbols square,Diamond,Circle with tooltip and hover events
    dots
      .attr(
        'd',
        symbol.type(function (coordinates) {
          if (coordinates.customShape) {
            return coordinates.customShape;
          } else if (coordinates.shape === 'square') {
            return d3.symbolSquare;
          } else {
            return d3.symbolCircle;
          }
        }),
      )
      .style('fill', (coordinates) =>
        coordinates.pointColor ? coordinates.pointColor : '',
      )
      .style('cursor', 'pointer')
      .attr('transform', function (coordinates) {
        return (
          'translate(' +
          xScale(Number(coordinates.x)) +
          ',' +
          yScale(Number(coordinates.y)) +
          ')'
        );
      });

    //Create Custom Label for points
    d3.select('g')
      .append('rect')
      .attr('ry', 5)
      .attr('rx', 5)
      .attr('fill', '#ccc')
      .attr('stroke', '#999')
      .attr('stroke-width', 1);

    const parent = d3.select('g').append('g').lower();
    const box = (parent.node() as SVGAElement).getBBox();

    // Attaching the custom box to groups
    groups
      .append('rect')
      .attr('x', (coordinates) =>
        xScale(
          width && width > SVG_DEFAULT_WIDTH
            ? coordinates.shape === 'square'
              ? Number(coordinates.x) - 9
              : coordinates.shape === 'circle'
                ? Number(coordinates.x) === xAxisTicks[xAxisTicks.length - 1]
                  ? Number(coordinates.x) - 5
                  : Number(coordinates.x) - 9
                : coordinates.shape === 'diamond'
                  ? Number(coordinates.x) - 1
                  : Number(coordinates.x)
            : coordinates.shape === 'square'
              ? Number(coordinates.x) - 13
              : coordinates.shape === 'circle'
                ? Number(coordinates.x) === xAxisTicks[xAxisTicks.length - 1]
                  ? Number(coordinates.x) - 6
                  : Number(coordinates.x) - 13
                : coordinates.shape === 'diamond'
                  ? Number(coordinates.x) - 2
                  : Number(coordinates.x) - 8,
        ),
      )
      .attr('y', (coordinates) =>
        yScale(
          coordinates.shape === 'square'
            ? Number(coordinates.y) + 11
            : coordinates.shape === 'circle'
              ? Number(coordinates.y) - 4
              : coordinates.shape === 'diamond'
                ? Number(coordinates.y) + 4
                : Number(coordinates.y),
        ),
      )
      .attr('width', box?.width + 40)
      .attr('height', box?.height + 15)
      .attr('fill', 'white')
      .attr('fill-opacity', '0.6')
      .style('transform', 'translateX(15px')
      .style('position', 'absolute')
      .style('cursor', 'pointer');

    let customText;

    if (isPDS === true) {
      customText = groups
        .append('svg:text')
        .attr('dx', (coordinates) =>
          xScale(
            width && width > SVG_DEFAULT_WIDTH
              ? coordinates.shape === 'square'
                ? Number(coordinates.x) - 2
                : coordinates.shape === 'circle'
                  ? Number(coordinates.x) === xAxisTicks[xAxisTicks.length - 1]
                    ? Number(coordinates.x) + 3
                    : Number(coordinates.x) - 2
                  : coordinates.shape === 'diamond'
                    ? Number(coordinates.x) +
                      (6 * xAxisTicks[xAxisTicks.length - 1]) / 100
                    : Number(coordinates.x)
              : coordinates.shape === 'square'
                ? Number(coordinates.x) - 2
                : coordinates.shape === 'circle'
                  ? Number(coordinates.x) === xAxisTicks[xAxisTicks.length - 1]
                    ? Number(coordinates.x) + 6
                    : Number(coordinates.x) - 2
                  : coordinates.shape === 'diamond'
                    ? Number(coordinates.x) + 9
                    : Number(coordinates.x),
          ),
        )
        .attr('dy', (coordinates) =>
          yScale(
            coordinates.shape === 'square'
              ? Number(coordinates.y) + 6
              : coordinates.shape === 'circle'
                ? Number(coordinates.y) - 10
                : coordinates.shape === 'diamond'
                  ? Number(coordinates.y) - 2
                  : Number(coordinates.y),
          ),
        )
        .attr(
          'font-size',
          (coordinates) => `${coordinates?.pointLabelFontSize}px`,
        )
        .attr('text-anchor', 'middle')
        .attr('line-height', '5')
        .attr('id', function (coordinates) {
          return `d3-point-label-${coordinates.pointColor}-${coordinates.index}`;
        })
        .style('stroke', 'none')
        .style('cursor', 'pointer')
        .style('transform', 'translateY(-24px')
        .style('fill-opacity', 1);
    } else {
      customText = groups
        .append('svg:text')
        .attr('dx', (coordinates) =>
          xScale(
            width && width > SVG_DEFAULT_WIDTH
              ? Number(coordinates.x)
              : Number(coordinates.x) - 2,
          ),
        )
        .attr('dy', (coordinates) => yScale(Number(coordinates.y) + 5))
        .attr(
          'font-size',
          (coordinates) => `${coordinates?.pointLabelFontSize}px`,
        )
        .attr('text-anchor', 'middle')
        .attr('line-height', '5')
        .attr('id', function (coordinates) {
          return `d3-point-label-${coordinates.pointColor}-${coordinates.index}`;
        })
        .style('stroke', 'none')
        .style('cursor', 'pointer')
        .style('fill-opacity', 1);
    }

    customText
      .append('svg:tspan')
      .attr('fill', 'rgba(31, 31, 31, 0.3)')
      .text(function (coordinates) {
        return `(${coordinates.index})`;
      });
    customText
      .append('svg:tspan')
      .attr('fill', (coordinates) =>
        coordinates.pointColor ? coordinates.pointColor : '',
      )
      .text(function (coordinates) {
        return coordinates.pointLabel ? coordinates.pointLabel : '';
      });

    // Custom events for mouseover for all group elements
    groups
      .on('mouseover', function (event, coordinates) {
        d3.select(this).raise();
        tooltip.transition().duration(200).style('opacity', 0.9);
        // @ts-ignore
        tooltip.html(coordinates.tooltipContent);
        tooltip
          // @ts-ignore
          .style('left', event.pageX + 'px')
          // @ts-ignore
          .style('top', event.pageY + 'px');
      })
      .on('mouseout', function () {
        tooltip.transition().duration(500).style('opacity', 0);
      });
  }, [coordinates]);

  return (
    <div className="D3" id={id}>
      <svg ref={svgRef}></svg>
    </div>
  );
};
