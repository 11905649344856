import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Blocker } from 'react-router-dom';
import Workspace from '..';
import NarrowMenuContainer from '../../../components/NarrowMenuContainer';
import { NarrowMenuItemProps } from '../../../components/NarrowMenuItem';
import OutsideClickHandler from '../../../components/OutsideClickHandler';
import TabContent from '../../../components/TabContent';
import TabGroup from '../../../components/TabGroup';
import TabItem from '../../../components/TabItem';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import { RootState } from '../../../store/rootReducer';
import DriveSelection from '../Dimensioning/Components/DriveSelection';
import { MotorSelection } from '../Dimensioning/Components/MotorSelection';
import TransformerSelection from '../Dimensioning/Components/TransformerSelection';
import {
  menuItemData,
  workspaceTabData,
} from '../Dimensioning/models/WorkspaceTabModel';
import Efficiency from '../Efficiency/Components/Efficiency';
import Selection from '../Selection/Components/Selection';
import {
  addEfficiencyTab,
  setActiveComponent,
  setActiveTab,
  setEfficiencyTabStatus,
} from '../store/WorkspaceReducer';



const WorkspaceTabs = ({ blocker }: { blocker: Blocker }) => {
  const { isMobile } = useDeviceDetect();

  const workspace = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );

  const sidebarOpen = useSelector(
    (state: RootState) => state.workspace.sidebarOpen,
  );

  const currentAppRank = useSelector(
    (state: RootState) => state.workspace.currentAppRank,
  );

  const dimensionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      ?.dimensionDetails;
  });

  const [menuOpen, setMenuOpen] = useState(false);
  const [activeTabs, setActiveTabs] = useState<menuItemData[]>([]);
  const [selectedSubTab, setSelectedSubTab] = useState('MotorInfoTab');
  const [addTabDisabled, setAddTabDisabled] = useState(true);

  const dispatch = useDispatch();
  const { t } = useTranslate();

  const onAddTabClick = () => {
    setMenuOpen(!menuOpen);
  };

  const onMenuClick = (menuItem: string | number, data: menuItemData) => {
    let active = activeTabs.filter((a) => {
      return a.value === menuItem;
    });
    if (active.length === 0) {
      let tabs = [...activeTabs, data];
      setActiveTabs(tabs);
      setMenuOpen(false);
      dispatch(setEfficiencyTabStatus(true));
      dispatch(setActiveTab('efficiency'));
    }
  };

  useEffect(() => {
    if (activeTabs.length > 0) {
      setTimeout(() => {
        dispatch(setActiveTab(activeTabs[activeTabs.length - 1].value));
      }, 500);
    }
  }, [activeTabs]);

  useEffect(() => {
    if (workspace && workspace.efficiencyTab === false) {
      setActiveTabs([]);
    } else if (workspace && workspace.efficiencyTab === true) {
      onMenuClick('efficiency', workspaceTabData[1]);
    }
    dispatch(setActiveTab('selection'));
  }, [workspace?.efficiencyTab]);

  const closeTab = (data: menuItemData) => {
    const arr = activeTabs.filter((a) => a.value !== data.value);
    setActiveTabs(arr);
    dispatch(setActiveTab(data.gotoTabonClose));
    if (data.value === 'efficiency') {
      dispatch(addEfficiencyTab(false));
    }
  };

  const getNarrowMenuItem = (): NarrowMenuItemProps[] => {
    let menuItems: NarrowMenuItemProps[] = [];
    workspaceTabData?.forEach((data: menuItemData) => {
      let disable =
        data.value === 'efficiency' && activeTabs.length > 0 ? true : false;
      menuItems.push({
        label: data.label,
        value: data.value,
        data: data,
        onClick: data.click ? onMenuClick : undefined,
        disabled: data.value === 'efficiency' ? disable : data.disabled,
      });
    });
    return menuItems;
  };

  const handleOnClickMenuItem = (menuItem: NarrowMenuItemProps) => {
    if (menuItem.onClick) menuItem.onClick(menuItem.value, menuItem.data);
  };

  useEffect(() => {
    if (dimensionData?.addEfficiencyTab) {
      onMenuClick('efficiency', workspaceTabData[1]);
    }
  }, [dimensionData?.addEfficiencyTab]);

  useEffect(() => {
    dispatch(setActiveTab('selection'));
    if (workspace?.activeComponent === 'motorDimension')
      setSelectedSubTab('MotorInfoTab');
    else setSelectedSubTab('DrivInfoTab');
  }, [workspace?.activeComponent, currentAppRank]);

  useEffect(() => {
    let motor =
      dimensionData?.selectMotorResult?.dimension?.dimensionResult
        ?.supplyDriveLoads?.[0]?.motorUnit?.results;
    let drives =
      dimensionData?.selectDriveResult?.dimension?.dimensionResult
        ?.supplyDriveLoads?.[0]?.inverterUnit?.results;
    let driveTypeCode = '';
    if (drives && drives.length > 0) {
      for (let drive of drives) {
        if (drive.name === 'TypeDesignation') {
          driveTypeCode = drive.value;
        }
      }
    }
    let motorCalId = '';
    if (motor && motor.length > 0) {
      for (let mot of motor) {
        if (mot.name === 'CalcID' || mot.name === 'Calc ID') {
          motorCalId = mot.value;
        }
      }
    }
    if (motorCalId.length !== 0 || driveTypeCode.length !== 0) {
      setAddTabDisabled(false);
    }
    if (motorCalId.length === 0 && driveTypeCode.length === 0) {
      setAddTabDisabled(true);
    }
  }, [dimensionData?.selectMotorResult, dimensionData?.selectDriveResult]);

  const mobileLayout = () => {
    return (
      <>
        {isMobile && (
          <div className="toggle-button-container" data-testid="toggle-button-container">
            <TabGroup
              selectedTabId={selectedSubTab}
              size="small"
              className="result-tabs"
            >
              <TabItem
                label={t(I18.dimensioning_tab_motor_title)}
                closable={false}
                tabId="MotorInfoTab"
                onTabClick={() => {
                  setSelectedSubTab('MotorInfoTab');
                  dispatch(setActiveComponent('motorDimension'));
                }}
              ></TabItem>
              <TabItem
                label={t(I18.dimensioning_tab_drive_title)}
                closable={false}
                tabId="DrivInfoTab"
                onTabClick={() => {
                  setSelectedSubTab('DrivInfoTab');
                  dispatch(setActiveComponent('driveDimension'));
                }}
              ></TabItem>
            </TabGroup>
          </div>
        )}
      </>
    );
  };
  return (
    <div className="workspace-tab">
      {currentAppRank != -1 ? (
        <TabGroup
          size={'small'}
          selectedTabId={workspace.activeTab}
          variant="primary-tab"
          className="tab-group"
        >
          <TabItem
            className="workspace-dimension-tab"
            label={t(I18.workspace_tab_selection_dimensioning_title)}
            tabId={'selection'}
            variant={'primary-tab'}
            closable={false}
            size={'medium'}
            data-rf="selection-tab"
            onTabClick={() => {
              dispatch(addEfficiencyTab(false));
              dispatch(setActiveTab('selection'));
            }}
          >
            {/* <img
              src="selection-dimension-tab.svg"
              alt="Selection and Dimension"
              slot="tab-icon"
              /> */}
          </TabItem>
          <div className="disp-flex">
            {activeTabs.length > 0 &&
              activeTabs.map((data,index) => {
                return (
                  <TabItem
                    key={data.value}
                    className={data.className}
                    icon={data.icon}
                    label={data.label}
                    tabId={data.value}
                    variant={data.variant}
                    closable={data.closable}
                    size={'small'}
                    data-rf={data.datarf}
                    onTabClick={() => {
                      dispatch(setActiveTab(data.value));
                    }}
                    onTabClose={() => closeTab(data)}
                    data-testid={`workspacetabs-active-item-${index}`}
                  >
                    {/* <img
                    src="efficiency-tab.svg"
                    alt="Efficiency"
                    slot="tab-icon"
                    /> */}
                  </TabItem>
                );
              })}
          </div>
          <TabItem
            icon="plus-16"
            closable={false}
            size={'small'}
            data-rf="add"
            disabled={addTabDisabled}
            onClick={() => {
              onAddTabClick();
            }}
            id="tab-menu-button"
            data-testid={`workspacetabs-item-plus-icon`}
          />
          <OutsideClickHandler onClickOutside={() => setMenuOpen(false)}>
            <div slot="context-data" className="narrow-menu">
              <NarrowMenuContainer
                key={menuOpen.toString()}
                menuItems={getNarrowMenuItem()}
                onClickMenuItem={handleOnClickMenuItem}
                isShow={menuOpen}
              ></NarrowMenuContainer>
            </div>
          </OutsideClickHandler>
          <div
            id="workspace-tab-container"
            slot="container"
            className="workspace-tab-container"
          >
            <TabContent
              containerId={'selection'}
              className={
                workspace.activeTab === 'selection' ? 'unhide-activetab' : ''
              }
            >
              <div>
                {workspace?.activeComponent === 'workspace' && <Workspace />}
                {workspace?.activeComponent === 'selection' && <Selection />}
                {isMobile &&
                  workspace?.activeComponent !== 'workspace' &&
                  workspace?.activeComponent !== 'selection' &&
                  mobileLayout()}
                {workspace?.activeComponent === 'motorDimension' && (
                  <MotorSelection blocker={blocker} />
                )}
                {workspace?.activeComponent === 'driveDimension' && (
                  <DriveSelection isSidebarOpen={sidebarOpen} />
                )}
                {currentAppRank === -1 && (
                  <TransformerSelection isSidebarOpen={sidebarOpen} />
                )}
              </div>
            </TabContent>
            <TabContent
              containerId={'efficiency'}
              className={
                workspace.activeTab === 'efficiency' ? 'unhide-activetab' : ''
              }
            >
              <Efficiency selectedTabId={workspace.activeTab} />
            </TabContent>
          </div>
        </TabGroup>
      ) : (
        <div
          id="workspace-tab-container"
          slot="container"
          className="workspace-tab-container"
        >
          <div>
            {currentAppRank === -1 && (
              <TransformerSelection isSidebarOpen={sidebarOpen} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkspaceTabs;
