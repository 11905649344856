import Button from '../../../../components/Form/Button';
import Icon from '../../../../components/Icon';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import { generateKey } from '../../../../utils/GeneralUtils';
import TabGroup from '../../../../components/TabGroup';
import TabItem from '../../../../components/TabItem';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import TabContent from '../../../../components/TabContent';
import { selectionTableData } from '../models/MotorDefaultValues';
import { DriveSelectionResultProp } from '../models/DriveSelectionModels';
import DriveCatalogueData from './DriveCatalogueData';
import ReactApexCharts from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { dimensionCurrentGraphOptions } from '../../../../utils/GraphDefaultData';
import {
  CurrentCurves,
  PowerCurves,
  TorqueCurves,
} from '../models/MotorSelectionModels';
import ResultGraphModalDrive from './ResultGraphModalDrive';
import {
  addEfficiencyTab,
  setHasRetryCatalogDataSelected,
} from '../../store/WorkspaceReducer';
import { driveTableData } from '../models/DriveDefaultValues';
import ErrorAndWarningDialog from './ErrorAndWarningDialog';
import CommonLoader from '../../../../components/Loader';

const DriveSelectionResult = ({
  isLoading,
  isError,
}: DriveSelectionResultProp) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [efficiencyDisabled, setEfficiencyDisabled] = useState(true);
  const dimensionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      ?.dimensionDetails;
  });

  const errorData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.errorOrWarningDriveResponse,
  );

  useEffect(() => {
    if (
      Object.keys(dimensionData.dimensionOutputData).length > 0 &&
      dimensionData.dimensionOutputData?.supplyDriveLoads?.[0]?.motorUnit
    ) {
      setEfficiencyDisabled(false);
    }
  }, [dimensionData.dimensionOutputData, dimensionData.dimensionDataLoading]);

  const onEfficiencyButtonClick = () => {
    dispatch(addEfficiencyTab(true));
  };

  const resultData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.selectDriveResult,
  );

  let resultInverterData = undefined;
  let catalogueTableData = undefined;
  let selectionData = undefined;
  let currentCurves: CurrentCurves | undefined;
  let powerCurves: PowerCurves | undefined;
  let torqueCurves: TorqueCurves | undefined;
  if (
    resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit
      ?.results?.[0]?.value !== '' &&
    resultData?.dimension?.dimensionResult !== undefined
  ) {
    resultInverterData =
      resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
        ?.inverterUnit?.results;

    catalogueTableData =
      resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
        ?.inverterUnit?.results !== null
        ? resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
            ?.inverterUnit?.results
        : driveTableData;

    selectionData =
      resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
        ?.inverterUnit?.miscellaneousUnit?.selectionData !== null
        ? resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
            ?.inverterUnit?.miscellaneousUnit?.selectionData
        : selectionTableData;

    if (!isLoading) {
      powerCurves =
        resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit
          ?.miscellaneousUnit?.powerGraph;
      torqueCurves =
        resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit
          ?.miscellaneousUnit?.torqueGraph;
      currentCurves =
        resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.inverterUnit?.miscellaneousUnit?.currentGraph;
    }
  } else {
    catalogueTableData = driveTableData;
    selectionData = selectionTableData;
  }

  const [optionsRef] = useState<ApexOptions | null>(
    dimensionCurrentGraphOptions,
  );
  const [expandGraph, setExpandGraph] = useState(false);

  const handleExpandGraphClick = () => {
    setExpandGraph(true);
  };

  const onResultGraphModalClose = () => {
    setExpandGraph(false);
  };

  const onExportGraphClick = () => {
    setExpandGraph(false);
  };

  const text = catalogueTableData?.filter((i) => {
    if (i.name.toString().trim() === 'SelMethodLongText') {
      return i.name;
    }
  });

  let mountingTypeValue =
    resultData?.dimension?.catalogueDataDrive?.productAttributes?.MountingType
      ?.values[0]?.text;

  const driveTypecode =
    resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit
      ?.results?.[0]?.value ??
    resultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit
      ?.results?.[0]?.value;

  return (
    <>
      {isLoading && (
        <div className="motor-result-loader-container">
          <div>
            <CommonLoader
              size="small"
              indicatorType={'circular'}
              progressType={'indeterminate'}
              progressValue={50}
              type={'primary-black'}
            />
          </div>
          <div className="motor-result-loader-message">
            {'Loading drive data...'}
          </div>
        </div>
      )}{' '}
      {!isLoading && isError && (
        <div>
          <ErrorAndWarningDialog
            isError={true}
            isWarning={false}
            data={'Failed to load some drive data. Please retry after sometime'}
          />
          <div className="button-retry">
            <Button
              type="secondary"
              onClick={() => {
                dispatch(setHasRetryCatalogDataSelected(true));
              }}
            >
              {
                <Icon
                  slot="icon"
                  size="small"
                  name="synchronize"
                  theme="dark"
                />
              }
              {'Retry'}
            </Button>
          </div>
        </div>
      )}
      {!isError &&
        driveTypecode &&
        resultData?.dimension?.catalogueDataDrive &&
        Object.keys(resultData?.dimension?.catalogueDataDrive.productAttributes)
          .length === 0 && (
          <div className="dimension-info-notification">
            <img className="transformer-info-icon" src="/infoblue.svg" />
            <div>Some data is not available for this drive</div>
          </div>
        )}
      <div className="right-header">
        <div className="right-header-text">
          {t(I18.drive_selection_result_title)}
        </div>
        <div className="right-header-button-container">
          <Button
            type="primary-black"
            disabled={efficiencyDisabled || isLoading}
            onClick={
              efficiencyDisabled ? () => {} : () => onEfficiencyButtonClick()
            }
          >
            <div className="efficiency-button">
              {t(I18.drive_selection_result_efficiency_button)}
            </div>
          </Button>
        </div>
      </div>
      {errorData?.isError ||
      (!Boolean(driveTypecode) && !errorData.isWarning && errorData?.isError) ||
      (!Boolean(driveTypecode) &&
        errorData.isWarning &&
        !errorData?.isError) ? (
        <ErrorAndWarningDialog
          isError={errorData?.isError ?? driveTypecode === ''}
          isWarning={errorData?.isWarning}
          data={
            errorData?.errorDriveSelection ??
            (driveTypecode === ''
              ? 'Drive selection failed because there is no drive which meets the requirements'
              : null)
          }
        />
      ) : (
        <div>
          {errorData?.isWarning && (
            <div>
              <ErrorAndWarningDialog
                isError={errorData?.isError}
                data={errorData?.errorDriveSelection}
                isWarning={errorData?.isWarning}
              />
            </div>
          )}
          <div>
            <div className="subtitle-container" slot="left">
              <div className="subtitle-header">
                {t(I18.drive_selection_result_subtitle)}
              </div>
            </div>
            <div className="type-product-info-table-container">
              <table className="type-product-info-table">
                <thead>
                  <tr>
                    <th className="type-product-info-table-header"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className="type-product-row"
                    key={generateKey('Type Code')}
                  >
                    <td className="type-product-col">
                      {t(I18.drive_selection_result_typecode_label)}
                    </td>
                    <td className="type-product-col value-col">
                      {isLoading ? (
                        <div className="catalogue-commonLoader">
                          <CommonLoader
                            size="xsmall"
                            indicatorType={'circular'}
                            progressType={'indeterminate'}
                            progressValue={0}
                            type={'primary-blue'}
                          />
                        </div>
                      ) : resultInverterData !== null &&
                        resultInverterData !== undefined ? (
                        resultInverterData[0]?.value
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              {!isLoading && (
                <div>
                  <div className="img-and-desc-container">
                    <div className="img-container">
                      <img
                        src={
                          resultData?.dimension?.catalogueDataDrive
                            ?.productImages &&
                          resultData?.dimension?.catalogueDataDrive
                            ?.productImages.length > 0
                            ? resultData?.dimension?.catalogueDataDrive
                                ?.productImages?.[0]?.url
                            : ''
                        }
                        alt={
                          resultData?.dimension?.catalogueDataDrive
                            ?.productImages !== null
                            ? resultData?.dimension?.catalogueDataDrive
                                ?.productImages?.[0]?.url
                            : ''
                        }
                      />
                    </div>

                    <div className={'desc-container'}>
                      {resultData?.dimension?.catalogueDataDrive
                        ?.productAttributes?.CatalogDescription?.values[0]
                        ?.text && (
                        <div id="desc-container">
                          {
                            resultData?.dimension?.catalogueDataDrive
                              ?.productAttributes?.CatalogDescription?.values[0]
                              ?.text
                          }
                        </div>
                      )}
                      {resultData?.dimension?.catalogueDataDrive
                        ?.productAttributes?.ModuleFunction?.values[0]
                        ?.text && (
                        <div className="label-content">
                          <div className="label-title">
                            {'Module Function: '}
                          </div>
                          <div>
                            {resultData?.dimension?.catalogueDataDrive
                              ?.productAttributes?.ModuleFunction?.values[0]
                              ?.text ?? '-'}
                          </div>
                        </div>
                      )}
                      {resultData?.dimension?.catalogueDataDrive
                        ?.productAttributes?.FrameSize?.values[0]?.text && (
                        <div className="label-content">
                          <div className="label-title">{'Frame Size: '}</div>
                          <div>
                            {resultData?.dimension?.catalogueDataDrive
                              ?.productAttributes?.FrameSize?.values[0]?.text ??
                              '-'}
                          </div>
                        </div>
                      )}
                      {resultData?.dimension?.catalogueDataDrive
                        ?.productAttributes?.MountingType?.values[0]?.text && (
                        <div className="label-content">
                          <div className="label-title">{'Mounting Type: '}</div>
                          <div>
                            {resultData?.dimension?.catalogueDataDrive
                              ?.productAttributes?.MountingType?.values[0]?.text
                              ? mountingTypeValue?.includes('_NO UNIT$')
                                ? mountingTypeValue?.replace('_NO UNIT$', '')
                                : mountingTypeValue
                              : '-'}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {resultData?.dimension?.catalogueDataDrive?.productAttributes
                    ?.CatalogDescription?.values[0]?.text && (
                    <>
                      <div className="link-buttons-container">
                        <Button
                          type="discreet-black"
                          onClick={() => {
                            let searchItem =
                              resultData?.dimension?.catalogueDataDrive
                                ?.productAttributes?.ProductId?.values[0]?.text;
                            window.open(
                              `https://new.abb.com/products/${searchItem}`,
                              '_blank',
                            );
                          }}
                        >
                          {
                            <Icon
                              slot="icon"
                              size="small"
                              name="open-in-new-window"
                            />
                          }
                          {'Open product page'}
                        </Button>
                        <Button
                          type="discreet-black"
                          className="view-catalog-button"
                          href={
                            resultData?.dimension?.catalogueDataDrive
                              ?.catalogPDFURL
                          }
                          target="_blank"
                        >
                          {
                            <Icon
                              slot="icon"
                              size="small"
                              name="open-in-new-window"
                            />
                          }
                          {'View catalog'}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            {resultInverterData && !isLoading && (
              <div>
                <div className="result-notification-container">
                  <Icon
                    slot="icon"
                    name="information-circle-1"
                    className="result-notification-ifno-icon"
                    size="small"
                  />
                  {'Losses [W]: 100% at base speed is equal to base load power'}
                </div>
                {text?.length > 0 && (
                  <div className="selected-method-text">{text?.[0]?.value}</div>
                )}
              </div>
            )}
            <div>
              <TabGroup
                selectedTabId="catalogueData"
                size="small"
                className="result-tabs"
              >
                <TabItem
                  label={t(I18.motor_selection_result_catalogue_data_label)}
                  closable={false}
                  tabId="catalogueData"
                  className="result-tab-item"
                ></TabItem>
                <TabItem
                  label={t(I18.motor_selection_result_selection_data_label)}
                  closable={false}
                  tabId="selectionData"
                  className="result-tab-item"
                  disabled={isLoading}
                ></TabItem>
                <div slot="container">
                  <TabContent containerId={'catalogueData'}>
                    <DriveCatalogueData
                      catalogueTableData={catalogueTableData}
                      isLoading={isLoading}
                    />
                  </TabContent>
                  <TabContent containerId={'selectionData'}>
                    <table className="selection-data-table">
                      <thead>
                        <tr
                          className="selection-data-table-header-container"
                          key={generateKey('selectionData-header')}
                        >
                          <td>{'Limit'}</td>
                          <td>
                            {t(I18.motor_selection_result_required_label)}
                          </td>
                          <td>{t(I18.motor_selection_result_result_label)}</td>
                          <td>{t(I18.motor_selection_result_margin_label)}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {selectionData?.map((data, idx) => {
                          if (
                            idx >= 0 &&
                            !data.row[0].toLowerCase().includes('limit')
                          ) {
                            return (
                              <tr
                                key={generateKey('selectiondata' + idx)}
                                className="selection-data-table-row"
                              >
                                <th>{data.row[0]}</th>
                                <td>{data.row[1] ?? '-'}</td>
                                <td>{data.row[2] ?? '-'}</td>
                                <td>{data.row[3] ?? '-'}</td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                  </TabContent>
                </div>
              </TabGroup>
              {currentCurves && Object.keys(currentCurves).length > 0 && (
                <div className="result-graph-container">
                  <div className="expand-graph-row">
                    <div className="graph-title">{'Graph'}</div>
                    <div
                      role="button"
                      className="expand-graph"
                      onClick={handleExpandGraphClick}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          handleExpandGraphClick();
                        }
                      }}
                    >
                      <Icon slot="icon" size="small" name="pop-out-window" />
                    </div>
                  </div>

                  {optionsRef && (
                    <div className="result-graph-container">
                      <ReactApexCharts
                        options={Object.assign(
                          {},
                          dimensionCurrentGraphOptions,
                          {
                            xaxis: {
                              min: 0,
                              max: parseFloat(
                                currentCurves?.speedContLoad?.[
                                  currentCurves?.speedContLoad.length - 2
                                ][0].toString() ?? '0',
                              ),
                              tickAmount: 3,
                              title: {
                                text: 'Speed [rpm]',
                                style: {
                                  fontSize: '14px',
                                  fontWeight: 400,
                                },
                              },
                            },
                          },
                        )}
                        series={[
                          {
                            name: 'cont. load',
                            data: currentCurves?.speedContLoad.slice(0, -1),
                            color: '#000000',
                          },
                          {
                            name: 'cont. loadability',
                            data: currentCurves?.speedContLoadability.slice(
                              0,
                              -1,
                            ),
                            color: '#32cd32',
                          },
                          {
                            name: 'max. load',
                            data: currentCurves?.speedMaxLoad.slice(0, -1),
                            color: '#0000ff',
                          },
                          {
                            name: 'max. loadability',
                            data: currentCurves?.speedMaxLoadability.slice(
                              0,
                              -1,
                            ),
                            color: '#de4040',
                          },
                        ]}
                        type={'line'}
                        height={optionsRef?.chart?.height ?? 100}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            <ResultGraphModalDrive
              modalOpen={expandGraph}
              onClose={onResultGraphModalClose}
              onExportClick={onExportGraphClick}
              currentCurve={currentCurves}
              powerCurve={powerCurves}
              torqueCurve={torqueCurves}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DriveSelectionResult;
