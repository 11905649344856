import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
  TouchEvent,
} from 'react';
import './../../styles/ButtonGroupMobile.scss';
import Icon from '../Icon';

interface ButtonGroupProps {
  className?: string;
  onBackClick: any;
  onResultClick?: (selectedOption: string) => void;
  leftButtonName?: string;
  rightButtonName?: string;
  isLeftBtnDisabled: boolean;
  isRightBtnDisabled: boolean;
  leftButtonOptions: string[];
}

function ButtonGroupMobile(props: PropsWithChildren<ButtonGroupProps>) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [isResultClicked, setIsResultClicked] = useState(false);

  const handleSelectOnClick = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };
  const handleResultClick = (selectedOption: string) => {
    if (props.onResultClick && !props.isRightBtnDisabled && selectedOption) {
      props.onResultClick(selectedOption);
      setIsResultClicked(true);
    }
  };

  const handleBackClick = () => {
    props.onBackClick();
    setIsResultClicked(false);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = useCallback((event: TouchEvent | Event) => {
    const dropdown = dropdownRef.current;

    if (dropdown && !dropdown.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('touchstart', handleOutsideClick);

    return () => {
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div className="button-group-mobile">
      {!isResultClicked ? (
        <>
          <div
            className="select-option-container"
            id="select-option-container"
            ref={dropdownRef}
          >
            <button
              className="dropdown-toggle"
              id="dropdown-toggle"
              name={'Select'}
              onClick={handleSelectOnClick}
            >
              <span className="button-name">{'Select'}</span>
              <Icon
                slot="icon"
                size="small"
                name={dropdownOpen ? 'caret-down' : 'caret-up'}
                theme="light"
              />
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu">
                {props.leftButtonOptions.map((option) => {
                  return (
                    <div
                      role="button"
                      key={option}
                      onClick={() => handleOptionClick(option)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleOptionClick(option);
                        }
                      }}
                      className={`dropdown-option ${
                        selectedOption && option === selectedOption
                          ? 'selected-option'
                          : ''
                      }`}
                    >
                      {option}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="result-button">
            <button
              className={`right-button button-group-common ${
                props.isRightBtnDisabled || !selectedOption ? 'disabled' : ''
              }`}
              onClick={() => handleResultClick(selectedOption)}
            >
              <span className={'right-button-name'}>
                {!isResultClicked ? props.rightButtonName : ''}
              </span>
              <Icon
                slot="icon"
                className={
                  props.isRightBtnDisabled || !selectedOption
                    ? 'arrow-icon-disabled'
                    : ''
                }
                size="small"
                name={'right-arrow'}
                theme="light"
              />
            </button>
          </div>
        </>
      ) : (
        <div>
          <button
            className={`right-button button-group-common`}
            onClick={handleBackClick}
          >
            <Icon slot="icon" size="small" name={'left-arrow'} theme="light" />
            <span className={'back-button-name'}>{'Back'}</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default ButtonGroupMobile;
