import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import PDSEfficiency from './PDSEfficiency';
import MotorEfficiency from './MotorEfficiency';
import DriveEfficiency from './DriveEfficiency';

export interface EfficiencyProp {
  selectedTabId: string;
}

const Efficiency = ({ selectedTabId }: EfficiencyProp) => {
  const dimensionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      ?.dimensionDetails;
  });
  let motor =
    selectedTabId === 'efficiency' &&
    dimensionData?.selectMotorResult?.dimension?.dimensionResult
      ?.supplyDriveLoads?.[0]?.motorUnit?.results;
  let drives =
    selectedTabId === 'efficiency' &&
    dimensionData?.selectDriveResult?.dimension?.dimensionResult
      ?.supplyDriveLoads?.[0]?.inverterUnit?.results;
  let driveTypeCode = '';
  if (drives && drives.length > 0) {
    for (let drive of drives) {
      if (drive.name === 'TypeDesignation') {
        driveTypeCode = drive.value;
      }
    }
  }
  let motorCalId = '';
  if (motor && motor.length > 0) {
    for (let mot of motor) {
      if (mot.name === 'Calc ID') {
        motorCalId = mot.value;
      }
    }
  }

  return (
    <div>
      {(dimensionData?.selectMotorResult?.dimension?.dimensionResult
        ?.supplyDriveLoads?.length > 0 ||
        dimensionData?.selectDriveResult?.dimension?.dimensionResult
          ?.supplyDriveLoads?.length > 0) && (
        <>
          {motorCalId.length !== 0 && driveTypeCode.length !== 0 && (
            <PDSEfficiency selectedTabId={selectedTabId} />
          )}

          {motorCalId.length !== 0 &&
            driveTypeCode.length === 0 &&
            selectedTabId === 'efficiency' && <MotorEfficiency />}

          {driveTypeCode.length !== 0 &&
            motorCalId.length === 0 &&
            selectedTabId === 'efficiency' && <DriveEfficiency />}
        </>
      )}
    </div>
  );
};

export default Efficiency;
