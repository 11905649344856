import { AmbientConditionValues } from '../../models/WorkspaceModels';

export interface menuItemData {
  label: string;
  value: string;
  className?: string;
  icon?: string;
  variant?: string;
  closable?: boolean;
  datarf?: string;
  url: string;
  click: boolean;
  key: string;
  disabled: boolean;
  redirectUrl: string;
  gotoTabonClose: string;
}

export interface TabDetails {
  activeTabs: menuItemData[];
  selectedTab: string;
}

export const workspaceTabData: menuItemData[] = [
  {
    label: 'Selection and Sizing',
    className: 'workspace-dimension-tab',
    value: 'selection',
    variant: 'primary-tab',
    closable: false,
    datarf: 'selection-tab',
    url: '/workspace/dimensioning',
    click: false,
    key: 'sel',
    disabled: true,
    redirectUrl: '/workspace/dimensioning',
    gotoTabonClose: 'selection',
  },
  {
    label: 'Efficiency',
    className: 'workspace-efficiency-tab',
    value: 'efficiency',
    variant: 'primary-tab',
    closable: true,
    datarf: 'efficiency-tab',
    url: '/workspace/efficiency',
    click: true,
    key: 'eff',
    disabled: false,
    redirectUrl: '/workspace/dimensioning',
    gotoTabonClose: 'selection',
  },
  // {
  //     label: "Harmonics",
  //     value: "harmonics",
  //     icon: "settings-16",
  //     variant: 'primary-tab',
  //     closable: true,
  //     datarf: "harmonics-tab",
  //     url: "",
  //     click: true,
  //     key: "har",
  //     disabled: true,
  //     redirectUrl: "/workspace/dimensioning",
  //     gotoTabonClose: "efficiency"
  // },
  // {
  //     label: "Configuration",
  //     value: "configration",
  //     icon: "settings-16",
  //     variant: 'primary-tab',
  //     closable: true,
  //     datarf: "configuration-tab",
  //     url: "",
  //     click: true,
  //     key: "config",
  //     disabled: true,
  //     redirectUrl: "/workspace/efficiency",
  //     gotoTabonClose: "harmonics"
  // },
  // {
  //     label: "Documents",
  //     value: "documents",
  //     icon: "settings-16",
  //     variant: 'primary-tab',
  //     closable: true,
  //     datarf: "documents-tab",
  //     url: "",
  //     click: true,
  //     key: "doc",
  //     disabled: true,
  //     redirectUrl: "/workspace/efficiency",
  //     gotoTabonClose: "configuration"
  // }
];

export interface AmbientConditionEditModalProps {
  modalOpen: boolean;
  onClose: () => void;
  onConfirmClick: (ambientConditionValues: AmbientConditionValues) => void;
}
