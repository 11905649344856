import React from 'react';
import Modal from '../../../components/Modal';
import Button from '../../../components/Form/Button';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';

interface ConfirmationModalProps {
  modalOpen?: boolean;
  onClose?: () => void;
  modalTitle: string;
  modalContentText: string;
  onClick: () => void;
  buttonText?: string;
  data_testid?: string;
}

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
  modalOpen,
  onClose,
  modalTitle,
  modalContentText,
  onClick,
  buttonText,
  data_testid,
}) => {
  const { t } = useTranslate();
  const openButtonClick = () => {
    onClose?.();
    onClick();
  };

  return (
    <Modal
      open={modalOpen}
      onModalClose={onClose}
      modalTitle={modalTitle}
      showCloseButton={true}
      type="default"
      showHederSeparator={buttonText ? 'false' : 'true'}
      show-footer-separator="true"
      backdropClick={false}
      data-testid={data_testid}
    >
      <div
        id="confirmation-modal-content"
        className="modal-content modal-container"
        data-rf="confirmation-modal-content"
      >
        <div data-rf="confirmation-modal-description">{modalContentText}</div>
        <div className="modal-button-container">
          <div className="open-button-container">
            <Button
              type="primary-black"
              size="small"
              onClick={openButtonClick}
              className="open-button"
              data-rf="confirmation-modal-open-button"
            >
              <img
                src="open-in-new-window-light-theme.svg"
                alt="open-in-new-tab"
                slot="icon"
                hidden={!!buttonText}
              />
              {buttonText ?? t(I18.modal_open_button)}
            </Button>
          </div>
          <div className="cancel-button-container">
            <Button
              type="tertiary"
              size="small"
              onClick={onClose}
              className="cancel-button"
              data-rf="confirmation-modal-cancel-button"
            >
              {t(I18.modal_cancel_button)}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
