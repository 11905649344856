import { ProjectActionTypes, ProjectsState } from '../../models/homepageModel';
import { ActionTypes } from '../actions/actionTypes';

const initialState: ProjectsState = {
  projectData: {
    projectId: '',
    projectName: '',
    projectType: '',
    createdDate: '',
    modifiedDate: '',
    destinationCountry: '',
    countryName: '',
  },
  projectTypeSelected: '1',
};

const projectId = sessionStorage.getItem('id');
const projectName = sessionStorage.getItem('name');

const homepagereducer = (
  state = initialState,
  action: ProjectActionTypes
): ProjectsState => {
  switch (action.type) {
    case ActionTypes.CREATE_PROJECT:
      return {
        ...state,
        projectData: {
          ...action.payload,
          projectId: action?.payload.projectId ?? projectId,
          projectName: action?.payload.projectName ?? projectName,
          createdDate:
            action?.payload?.createdDate ?? state?.projectData?.createdDate,
          modifiedDate:
            action?.payload?.modifiedDate ?? state?.projectData?.modifiedDate,
        },
      };

    case ActionTypes.PROJECT_TYPE_SELECTED:
      return { ...state, projectTypeSelected: action.payload };
    default:
      return state;
  }
};

export default homepagereducer;
