import Card from '../../../components/Card';
import { useTranslate } from '../../../i18translate/Hooks';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetProjectTypesQuery } from '../../Home/services/APIMainService';
import { SegmentCardDetails } from '../models/WorkspaceModels';
import { useDispatch } from 'react-redux';
import CommonLoader from '../../../components/Loader';
import { projectAndMotorTypeSelected } from '../store/WorkspaceReducer';
import { setActiveComponent } from '../store/WorkspaceReducer';

function SegmentCards() {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { isMobile } = useDeviceDetect();
  const navigate = useNavigate();
  const { data: projectTypes, isLoading } = useGetProjectTypesQuery(undefined);
  const [segmentCards, setSegmentCard] = useState<SegmentCardDetails[]>([]);

  const fetchProjectDetails = async () => {
    const project = projectTypes?.projectTypes;
    let newCards: SegmentCardDetails[] = [];
    project?.map((item, idx) => {
      if (idx !== 1 && idx < 5) {
        let label = item.label.toLowerCase().replaceAll(' ', '_');
        let imgLabel = item.label.toLowerCase().replaceAll(' ', '');
        let newCard: SegmentCardDetails = {
          onClick: () => {
            dispatch(
              projectAndMotorTypeSelected({
                projectType: item.value,
                projectName: item.label,
                motorType: 1,
              })
            );
            dispatch(setActiveComponent('motorDimension'));
          },
          imgSrc: `assets/images/${imgLabel}.svg`,
          alt: imgLabel,
          title: t(`workspace_segment_cards_${label}_title`),
          content: t(`workspace_segment_cards_${label}_description`),
          dataRf: `${label}_card`,
          value: item.value,
        };
        newCards = [...newCards, newCard];
      } else return null;
    });
    setSegmentCard(newCards);
  };

  useEffect(() => {
    if (projectTypes !== undefined) {
      fetchProjectDetails();
    }
  }, [projectTypes, t, dispatch, navigate]);

  if (isLoading) {
    return (
      <div className="loader-container">
        <CommonLoader
          size="xlarge"
          indicatorType={'circular'}
          progressType={'indeterminate'}
          progressValue={50}
          type={'primary-black'}
        />
      </div>
    );
  }

  return (
    <>
      <div
        className="segment-card-container"
        data-testid="segment-card-container"
      >
        {segmentCards.map((segmentCard, idx) => {
          return (
            <Card
              key={(segmentCard.title, idx)}
              className="option-card"
              size={isMobile ? 'default' : 'larger-padding'}
              onClick={segmentCard.onClick}
              type="discreet"
              data-rf={segmentCard.dataRf}
              data-tesid=""
            >
              <div slot="header">
                <img src={segmentCard.imgSrc} alt={segmentCard.alt} />
                <div className="option-card-header">{segmentCard.title}</div>
                <div slot="contents" id="content">
                  {segmentCard.content}
                </div>
              </div>
              <div slot="footer" id="footer">
                <h6> Select </h6>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
}

export default SegmentCards;
