import { combineReducers } from '@reduxjs/toolkit';
import homepagereducer from '../pages/Home/store/reducers/reducer';
import {
  CatalogReportApi,
  DrivetypeInputDataApi,
  PISDataApi,
  articlesApi,
  countryApi,
  homeApi,
  localeApi,
} from '../pages/Home/services/APICommonService';
import { projectApi } from '../pages/Home/services/APIMainService';
import WorkspaceReducer from '../pages/Workspace/store/WorkspaceReducer';
import { dimensioningApi } from '../pages/Workspace/Dimensioning/services/APIDimensioningService';
import { selectionApi } from '../pages/Workspace/services/APISelectionService';
import { efficiencyApi } from '../pages/Workspace/Efficiency/services/APIEfficiencyService';

const rootReducer = combineReducers({
  home: homepagereducer,
  [homeApi.reducerPath]: homeApi.reducer,
  [localeApi.reducerPath]: localeApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
  [articlesApi.reducerPath]: articlesApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [dimensioningApi.reducerPath]: dimensioningApi.reducer,
  [PISDataApi.reducerPath]: PISDataApi.reducer,
  [CatalogReportApi.reducerPath]: CatalogReportApi.reducer,
  [selectionApi.reducerPath]: selectionApi.reducer,
  [efficiencyApi.reducerPath]: efficiencyApi.reducer,
  [DrivetypeInputDataApi.reducerPath]: DrivetypeInputDataApi.reducer,
  workspace: WorkspaceReducer.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
