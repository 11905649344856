import { iesLimits } from '../../models/EfficiencyModel';
import React, { FC } from 'react';
import '../Styles/IESClassGraph.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';

interface IRangeChartLine {
  min: number;
  max: number;
  text: string;
  extraText?: string;
  hideLabel?: boolean;
  width: number;
  color: string;
  rightClearanceMin: number;
  rightClearanceMax: number;
}

interface IPDSRangeChart {
  rangeList?: iesLimits[];
  relativeLoss?: string;
  rangeIesCalss?: string;
}

const IESClassGraph: FC<IPDSRangeChart> = ({
  rangeList,
  relativeLoss,
  rangeIesCalss,
}) => {
  const isReportModalOpen = useSelector(
    (state: RootState) => state.workspace.isReportModalOpen,
  );

  const getColor = (item: iesLimits) => {
    switch (item.ies) {
      case 'IES0':
        return '#cc0815';
      case 'IES1':
        return '#ed5739';
      case 'IES2':
        return '#f2ac66';
      case 'IES3':
        return '#d6c918';
      case 'IES4':
        return '#75ba70';
      case 'IES5':
        return '#477a43';
      default:
        return 'white';
    }
  };
  let lines: IRangeChartLine[] | null = null;
  let actualLossClearance = 0;
  let maxRelLoss = 0;
  if (rangeList && rangeList.length > 0) {
    maxRelLoss = Number(rangeList[rangeList.length - 1].maxRelLoss);
    lines = [...rangeList].reverse().map((item) => {
      return {
        min: Number(item.minRelLoss),
        max: Number(item.maxRelLoss),
        text: item.ies,
        width: Number(
          ((Number(item.maxRelLoss) / maxRelLoss) * 100).toFixed(1),
        ),
        rightClearanceMin: Number(
          ((Number(item.minRelLoss) / maxRelLoss) * 100).toFixed(1),
        ),
        rightClearanceMax: Number(
          ((Number(item.maxRelLoss) / maxRelLoss) * 100).toFixed(1),
        ),
        color: getColor(item),
      };
    });
    actualLossClearance = Number(
      ((Number(relativeLoss) / maxRelLoss) * 100).toFixed(1),
    );
  }

  const isRelativeLossinIESRange =
    relativeLoss &&
    Number(relativeLoss) <= maxRelLoss &&
    Number(relativeLoss) >= 0
      ? true
      : false;

  return lines && lines.length > 0 ? (
    <>
      <div
        className="PDSRangeChart-Title"
        data-rf="pds-losses-iesclass-chart-heading"
      >
        PDS relative losses at(90,100) point compared to IES class
      </div>
      <div
        className="PDSRangeChart-Container"
        data-rf="pds-losses-iesclass-chart"
      >
        <div className="PDSRangeChart-Bar">
          {lines.map((iesItem, index) => (
            <React.Fragment key={iesItem.min}>
              <div
                className="PDSRangeChart-Block"
                style={{
                  backgroundColor: iesItem.color,
                  border: `1px solid ${iesItem.color}`,
                  width: lines
                    ? `${
                        index === lines.length - 1
                          ? iesItem.width
                          : iesItem.rightClearanceMax -
                            iesItem.rightClearanceMin
                      }%`
                    : '',
                }}
              ></div>
              {index == 0 ? (
                <>
                  <div
                    className="PDSRangeChart-Value"
                    style={{
                      right: `calc(${iesItem.rightClearanceMax}% - ${
                        isReportModalOpen ? '4%' : '3.9%'
                      })`,
                      top: '25px',
                    }}
                  >
                    <span>{iesItem.max}%</span>
                  </div>
                  <div
                    className="PDSRangeChart-DottedLine"
                    style={{
                      right: `calc(${iesItem.rightClearanceMax}% - 2px)`,
                      top: '42px',
                    }}
                  ></div>
                </>
              ) : null}

              <div
                className="PDSRangeChart-Value"
                style={{
                  right: `calc(${iesItem.rightClearanceMin}% - 0%)`,
                  top: index % 2 === 0 ? '75px' : '25px',
                }}
              >
                <span>{iesItem.min}%</span>
              </div>
              <div
                className="PDSRangeChart-DottedLine"
                style={{
                  right: `${iesItem.rightClearanceMin}%`,
                  top: index % 2 !== 0 ? '42px' : '',
                }}
              ></div>
            </React.Fragment>
          ))}
          {relativeLoss && isRelativeLossinIESRange ? (
            <>
              {' '}
              <div
                className="PDSRangeChart-SolidValue"
                style={{
                  right: `${actualLossClearance - 5}%`,
                  top: '5px',
                }}
              >
                <span>
                  {relativeLoss}% {rangeIesCalss}
                </span>
              </div>
              <div
                className="PDSRangeChart-SolidLine"
                style={{
                  right: `${actualLossClearance}%`,
                  top: '28px',
                }}
              ></div>
            </>
          ) : null}
        </div>
        <div className="PDSRangeChart-Footer">
          <div className="PDSRangeChart-Footer-Scale"></div>
          <div className="PDSRangeChart-Footer-Text">
            improving efficiency, lower losses compared to ref. PDS
          </div>
          <div className="PDSRangeChart-Legend">
            {lines.map((ies) => (
              <React.Fragment key={ies.text}>
                <div
                  className="PDSRangeChart-LegendColor"
                  style={{
                    backgroundColor: ies.color,
                  }}
                ></div>
                <div className="PDSRangeChart-LegendText">{ies.text}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  ) : null;
};
export default IESClassGraph;
