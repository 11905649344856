import { useState } from 'react';
import Icon from '../../../components/Icon';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { useTranslate } from '../../../i18translate/Hooks';
import DeleteProjectModal from './DeleteProjectModal';
import { RecentProjectMenuProps } from './types';
import driveAndMotorIcon from './../../../../src/assets/images/drive-motor-small.svg';
import plcHmiIcon from './../../../../src/assets/images/cpu-io.svg';

const RecentProjectMenu = ({
  openProjectButtonHandler,
  deleteProjectButtonHandler,
  projectName,
  deleteProjectLoading,
  isProjectTypeGoSelect,
}: RecentProjectMenuProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { t } = useTranslate();

  const ref = useOutsideClick(() => {
    setShowMenu(false);
  });

  const deleteButtonHandler = () => {
    deleteProjectButtonHandler();
    setShowMenu(false);
  };
  return (
    <>
      <div
        slot="contents"
        className="recent-project-menu-container"
        data-testid="project-menu-container"
      >
        <>
          <div className="recent-project-header">
            <div className="project-type-container">
              <img
                src={isProjectTypeGoSelect ? driveAndMotorIcon : plcHmiIcon}
                alt="plc-drivemotorimg"
              />
              <div className="project-type-title">
                {isProjectTypeGoSelect ? 'Drive & Motor' : 'PLC & HMI'}
              </div>
            </div>
            <div className="project-menu">
              <button
                className={` kebab-icon-container ${showMenu ? 'active' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMenu(true);
                }}
                data-testid="kebab-icon"
              >
                <Icon name="kebab" size="small" slot="icon" />
              </button>
            </div>
          </div>
          {showMenu ? (
            <div className="project-menu-container" ref={ref}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMenu(false);
                  openProjectButtonHandler();
                }}
                data-testid="open-text-button"
              >
                <Icon name="folder-open" size="small" slot="menu-icon" />
                <label>{t(`open_button`)}</label>
              </button>

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMenu(false);
                  setOpenDeleteModal(true);
                }}
                data-testid="delete-text-button"
              >
                <Icon name="trash" size="small" />
                <label>{t(`delete_button`)}</label>
              </button>
            </div>
          ) : (
            ''
          )}
        </>
      </div>

      <div slot="contents">
        <DeleteProjectModal
          closeDeleteModal={() => setOpenDeleteModal(false)}
          showModal={openDeleteModal}
          projectName={projectName}
          deleteButtonHandler={() => deleteButtonHandler()}
          deleteProjectLoading={deleteProjectLoading}
        />
      </div>
    </>
  );
};

export default RecentProjectMenu;
