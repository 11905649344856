import Oidc from 'oidc-client';

const logoutHandler = () => {
  const authority = process.env.REACT_APP_OIDC_CLIENT_STS_AUTHORITY!;
  const clientId = process.env.REACT_APP_OIDC_CLIENT_ID!;
  const redirectUri = `${process.env.REACT_APP_OIDC_CLIENT_ROOT}/signin.html`;
  const postLogoutRedirectUri = `${process.env.REACT_APP_OIDC_CLIENT_ROOT}/logout.html`;
  const silentRedirectUri = process.env.REACT_APP_API_SILENT_SIGNIN_REDIRECT!;

  const userManager = new Oidc.UserManager({
    authority,
    client_id: clientId,
    redirect_uri: redirectUri,
    post_logout_redirect_uri: postLogoutRedirectUri,
    silent_redirect_uri: silentRedirectUri,
    userStore: new Oidc.WebStorageStateStore({
      store: window.localStorage,
    }),
  });

  userManager
    .signoutRedirect()
    .then(() => {
      window.location.href =
        window.localStorage.getItem('logoutredirectUrl') || '/';
      console.log('abc');
    })
    .catch((e) => {
      console.error(`Error during logout: ${e}`);
    });

  return (
    <div>
      <head>
        <title>Signing Out</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="mask-icon"
          href="/favicon/GS_White_48X48.png"
          type="image/png"
          color="#5bbad5"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/favicon/GS_White_72x72.png"
          type="image/png"
        />
      </head>

      <body>
        <noscript>You need to enable JavaScript to run this app.</noscript>

        <div className="container">
          <div className="header">
            <div className="header-container">
              <img
                className="logo"
                src="./assets/icon/abb-logo-default.svg"
                alt="ABB Logo"
              />
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default logoutHandler;
/* document.addEventListener('DOMContentLoaded', () => {
  logoutHandler();
}); */
