import React from 'react';
import Modal from '../../../components/Modal';
import Button from '../../../components/Form/Button';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import Icon from '../../../components/Icon';

interface InformationModalProps {
  modalOpen?: boolean;
  onClose?: () => void;
  modalTitle: string;
  modalContentText: string;
  buttonText: string;
  onClick: () => void;
  isDelete?: boolean;
  isInfo?: boolean;
}

const InformationModal: React.FunctionComponent<InformationModalProps> = ({
  modalOpen,
  onClose,
  modalTitle,
  modalContentText,
  buttonText,
  onClick,
  isDelete,
  isInfo,
}) => {
  const { t } = useTranslate();
  const onButtonClick = () => {
    onClose?.();
    onClick();
  };

  return (
    <Modal
      open={modalOpen}
      onModalClose={onClose}
      modalTitle={modalTitle}
      showCloseButton={true}
      type="default"
      show-footer-separator="true"
      backdropClick={false}
    >
      <Icon
        size="medium"
        name={
          isDelete
            ? 'trash'
            : isInfo
            ? 'information-circle-2'
            : 'turn-counter-clockwise'
        }
        slot="title-icon"
        className="info-modal-trash-icon"
      />
      <div
        id="confirmation-modal-content"
        className="modal-content info-modal-container"
        data-rf="confirmation-modal-content"
      >
        <div data-rf="confirmation-modal-description">{modalContentText}</div>
        <div className="info-modal-button-container">
          <div className="info-cancel-button-container">
            <Button
              type="discreet-black"
              size="small"
              onClick={onClose}
              className="info-cancel-button"
              data-rf="confirmation-modal-cancel-button"
            >
              {t(I18.modal_cancel_button)}
            </Button>
          </div>
          <div className="open-button-container">
            <Button
              type="primary-black"
              size="small"
              onClick={onButtonClick}
              className="info-button"
              data-rf="confirmation-modal-open-button"
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InformationModal;
