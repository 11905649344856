import { I18 } from '../../../languages/I18';
import { useTranslate } from '../../../i18translate/Hooks';
import './styles/styles.scss';
import { RootState } from '../../../store/rootReducer';
import { useSelector } from 'react-redux';
import BOMDetails from './BOMDetails';
import ProjectData from './ProjectData';
import DimensioningData from './DimensioningData';
import TableOfContents from './TableOfContents';
import ProjectInformation from './ProjectInformation';
import { useEffect } from 'react';
import { appInsights } from '../../../services/AppInsight';

const Reports = () => {
  const { t } = useTranslate();

  useEffect(() => {
    appInsights.trackPageView({
      name: 'Report page',
      pageType: 'Sub page',
    });
  }, []);

  const projectData = useSelector((state: RootState) => state.home.projectData);

  const reportData = useSelector((state: RootState) => {
    return state.workspace;
  });

  return (
    <div className="report-section" id="report-section">
      {/* branding section */}
      {/* ABB logo */}
      <div className="brand-logo" data-testid="report-brand-logo">
        <img src="/assets/icon/abb-logo-default.svg"></img>
      </div>

      <div className="brand-color-bar"></div>
      <div className="report-header" id="report-header">
        {t(`report_header`)}
      </div>

      <div className="report-project">
        {reportData.reportName === ''
          ? projectData.projectName
          : reportData.reportName}
      </div>

      <ProjectInformation />
      {/* project description */}
      {/* <div className="report-project--description--container">
        <p className="report-project--description--heading">Description</p>
        <p className="report-project--description--text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
      </div> */}

      <TableOfContents />

      {/* user commentes section */}

      <BOMDetails />

      <ProjectData />

      <DimensioningData />

      <div id="pdf-section" className="common-disclimer">
        <div className="segment-subheader">
          <strong>Disclaimer</strong>
        </div>
        <div className="segment-type-content">
          The compilation of this report and the information contained herein
          relies on the accuracy of the data entered by the user of GoSelect as
          well on the actual operating and environmental conditions . The
          information shown in this report are to be used as a guide for
          internal evaluation purposes only and should not be relied on as
          accurate data or analysis. The information included in this report is
          provided “as-is” without warranty of any kind and does not constitute
          a guarantee that the information is complete, current, or correct. Any
          decision based on the information provided in this report is taken at
          the user’s own risk.
        </div>
      </div>
    </div>
  );
};

export default Reports;
