import { useEffect, useState } from 'react';
import Button from '../../../../components/Form/Button';
import IndustrySelection from './IndustrySelection';
import ApplicationSelection from './ApplicationSelection';
import RatingsSelection from './RatingsSelection';
import SelectionResult from './SelectionResult';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveComponent,
  setSelectionTab,
  setSelectionTabClick,
  motorLoadDataSave,
  projectAndMotorTypeSelected,
  setHasDimensionDataFetched,
  setMotorMotivators,
  setDriveMotivators,
} from '../../store/WorkspaceReducer';
import useDeviceDetect from '../../../../hooks/useDeviceDetect';
import { RootState } from '../../../../store/rootReducer';
import { driveFormDataSave } from '../../store/WorkspaceReducer';
import { appInsights } from '../../../../services/AppInsight';
import { MotorType } from '../../Dimensioning/models/DriveMotorEnums';

const Selection = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: 'Selection page',
      pageType: 'Sub page',
    });
  }, []);

  const dmsData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .selectionDetails;
  });

  const workspace = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );

  const [isResultPageLoading, setIsResultPageLoading] = useState(false);

  const { isMobile } = useDeviceDetect();

  const handleSelection = (data: string) => {
    dispatch(setSelectionTab(data));
  };

  const onStepButtonClick = (value: number) => {
    dispatch(setMotorMotivators(undefined));
    dispatch(setDriveMotivators(undefined));
    dispatch(setSelectionTabClick(value));
  };

  const handleDimensioningOnClick = () => {
    dispatch(setHasDimensionDataFetched(false));
    dispatch(
      projectAndMotorTypeSelected({
        projectType: workspace.drive?.driveGroup ?? '7',
        motorType:
          workspace.motor && workspace.motor.motorType
            ? (MotorType[
                workspace.motor.motorType
                  .trim()
                  .replace(/ +/g, '')
                  .toLowerCase() as keyof typeof MotorType
              ] ?? 1)
            : 1,
      }),
    );
    dispatch(
      motorLoadDataSave({
        power: dmsData?.power?.toString(),
        loadType: workspace.driveSizeParams?.loadData?.loadType ?? '1',
        overLoadType: workspace.driveSizeParams?.loadData?.overloadType ?? '0',
        dutyCycleTime: workspace.drive?.every ?? '600',
        maxLoadTime: workspace.drive?.overloadTime ?? '60',
        olBaseInput:
          dmsData.dutyTypeId === 2
            ? '100'
            : dmsData.dutyTypeId === 1
              ? '110'
              : dmsData.dutyTypeId === 3
                ? '150'
                : '100',
        maxSpeed: '1500',
        olMaxInput:
          dmsData.dutyTypeId === 2
            ? '100'
            : dmsData.dutyTypeId === 1
              ? '110'
              : dmsData.dutyTypeId === 3
                ? '150'
                : '100',
        powerMax: dmsData?.power?.toString(),
        nMin:
          workspace.driveSizeParams?.loadData?.loadType === '1'
            ? '400'
            : '1500',
        nBase: '1500',
        nMax: '1500',
      }),
    );
    dispatch(setActiveComponent('motorDimension'));
    dispatch(
      driveFormDataSave({
        driveName: workspace.drive?.name,
        type: workspace.drive?.driveType,
        noOfMotors: workspace.dimensionDetails.motorFormData.motorsCount,
        ipClass: workspace.dimensionDetails.motorFormData.ipClass,
        drivesCount: '1',
        construction: workspace.drive?.mountingType,
        cooling: workspace.drive?.cooling,
      }),
    );
  };

  return (
    <div
      id="selection-container"
      className="selection-page-container"
      data-testid="selection-page-container"
    >
      <div
        id="selection-application-button-container"
        className="application-button-container"
      >
        <div hidden={workspace?.selectionTabs.length < 1}>
          {workspace?.selectionTabs.map((selection, idx) => {
            return (
              <Button
                key={(selection.stepValue, idx)}
                className="selection-step-button"
                type="secondary"
                size="small"
                disabled={!selection.stepLabel || selection.stepLabel === ' '}
                onClick={() => onStepButtonClick(selection.stepValue)}
                data-rf={`selection-step-` + (idx + 1)}
                data-testid={`selection-step-` + (idx + 1)}
              >
                <div slot="menu">
                  {selection.stepValue}
                  {selection.stepLabel ? `. ${selection.stepLabel}` : null}
                </div>
              </Button>
            );
          })}
        </div>
        <div
          className="application-continue-button"
          hidden={workspace?.selectionTabs.length < 4}
          data-rf="application-continue-button"
          data-testid="application-continue-button"
        >
          <Button
            type="primary-black"
            size={isMobile ? 'xsmall' : 'medium'}
            data-rf="header-support-button"
            onClick={handleDimensioningOnClick}
            disabled={
              workspace?.selectionTabs.length < 4 || isResultPageLoading
            }
          >
            Continue to Sizing
          </Button>
        </div>
      </div>
      {workspace?.selectionTabs.length <= 3 && (
        <span className="application-step-container">
          Step {workspace?.selectionTabs.length} of 3
        </span>
      )}
      {workspace?.selectionTabs.length === 1 && (
        <IndustrySelection onIndustryCardClick={handleSelection} />
      )}
      {workspace?.selectionTabs.length === 2 && (
        <ApplicationSelection onApplicationCardClick={handleSelection} />
      )}
      {workspace?.selectionTabs.length === 3 && (
        <RatingsSelection
          onRatingsNextClick={handleSelection}
          onPreviousClick={onStepButtonClick}
        />
      )}
      {workspace?.selectionTabs.length === 4 && (
        <SelectionResult setIsResultPageLoading={setIsResultPageLoading} />
      )}
    </div>
  );
};

export default Selection;
