export interface NarrowMenuItemProps {
  type?: 'large' | 'medium';
  value: string | number;
  label: string;
  data: any;
  disabled: boolean;
  onClick?: (value: string | number, data: any) => void;
  dataRf?: string;
  dataTestId?: string;
}

function NarrowMenuItem(props: NarrowMenuItemProps) {
  const {
    type = 'medium',
    value,
    label,
    data,
    onClick,
    disabled,
    dataRf,
    dataTestId,
  } = props;
  const handleClick = () => {
    if (onClick && !disabled) onClick(value, data);
  };

  return (
    <li
      className={
        disabled
          ? ' body-1-regular menu-item menu-disabled'
          : `body-1-regular menu-item ${type}`
      }
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
      role="button"
      data-rf={dataRf}
      data-testid={dataTestId}
    >
      {label}
    </li>
  );
}

export default NarrowMenuItem;
