import React from 'react';
import ColumnSpan from '../../../../components/ColumnSpan';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import Icon from '../../../../components/Icon';
import Button from '../../../../components/Form/Button';
import { useState } from 'react';
import { useGetPartialLoadLossesMutation } from '../services/APIEfficiencyService';
import {
  PDSResults,
  UserDefinedLoadPoints,
  UDPInputs,
  StandardPoints,
} from '../../models/EfficiencyModel';
import { useDispatch, useSelector } from 'react-redux';
import SpeedTorqueSliders from './SpeedTorqueSliders';
import { debounce } from 'lodash';
import ButtonGroupCommonux from '../../../../components/ButtonGroup';
import ButtonGroupItemCommonux from '../../../../components/ButtonGroupItem';
import {
  getLoadBalancingSpeed,
  getLoadBalancingTorquePercentage,
} from '../Utils/UdpPointsUnitConversion';
import { RootState } from '../../../../store/rootReducer';
import {
  setEfficiencyUDPLossesData,
  setEfficiencyUdpData,
} from '../../store/WorkspaceReducer';

interface UDPProps {
  data: PDSResults;
  udpPoints: (ind: UserDefinedLoadPoints[]) => void;
}

const UserDefinedLoadPointsSection = React.memo((props: UDPProps) => {
  const efficiencyData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank];
  });
  const { t } = useTranslate();
  const [unitChange, setUnitChange] = useState(false);
  const udpPoints = efficiencyData?.efficiencyUDPLossesdata ?? [];
  const speedUnit = efficiencyData?.efficiencyUDPData?.speedUnit ?? '%';
  const torqueUnit = efficiencyData?.efficiencyUDPData?.torqueUnit ?? '%';
  const torqueOrPower =
    efficiencyData?.efficiencyUDPData?.torqueOrPower ?? 'torque';

  const dimensionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      ?.dimensionDetails;
  });

  const nullObject: StandardPoints = {
    absoluteLoss: '-',
    absoluteLossUnit: 'W',
    relativeLoss: '-',
    relativeLossUnit: '%',
    efficiency: '-',
    efficiencyUnit: '%',
    motorAbsoluteLoss: '-',
    driveAbsoluteLoss: '-',
    speed: '45',
    torque: '45',
  };
  const inputData = {
    driveTypecode: props.data.pdsDriveDetails.driveTypeCode,
    motorID: props.data?.motorTechnicalData?.motorId,
    speed: getLoadBalancingSpeed(
      dimensionData.motorLoadData,
      dimensionData?.selectMotorResult.dimension.dimensionResult,
      45,
    ).toString(),
    torque: getLoadBalancingTorquePercentage(
      dimensionData?.selectMotorResult.dimension.dimensionResult,
      45,
    ).toString(),
  } as UDPInputs;

  const [getPartailLoadLoss, { isLoading }] = useGetPartialLoadLossesMutation();
  const dispatch = useDispatch();

  const onAddClick = () => {
    if (udpPoints.length < 9) {
      getPartailLoadLoss(inputData).then((response) => {
        if (response.data && response?.data !== null) {
          let udpData = {} as UserDefinedLoadPoints;
          udpData.data = response.data[0];
          udpData.userInputSpeed = '45';
          udpData.userInputTorque = '45';
          udpData.speedUnit = speedUnit;
          udpData.torqueUnit = torqueUnit;
          dispatch(setEfficiencyUDPLossesData([...udpPoints, udpData]));
        } else if (response?.data === null) {
          let udpData = {} as UserDefinedLoadPoints;
          udpData.data = nullObject;
          udpData.userInputSpeed = '45';
          udpData.userInputTorque = '45';
          udpData.speedUnit = speedUnit;
          udpData.torqueUnit = torqueUnit;
          dispatch(setEfficiencyUDPLossesData([...udpPoints, udpData]));
        }
      });
    }
  };

  const onSliderValChange = debounce(
    (
      ind: number,
      value: number,
      type: string,
      speedVal: number,
      torqueVal: number,
      speedUnit: string,
      torqueUnit: string,
      oldSpeedInPercentage: string,
      oldTorqueInPercentage: string,
      unitChange?: boolean,
    ) => {
      if (!unitChange) {
        let newInputs = inputData;
        if (type === 'speed') {
          newInputs.speed = value.toString();
          newInputs.torque = oldTorqueInPercentage;
        } else {
          newInputs.speed = oldSpeedInPercentage;
          newInputs.torque = value.toString();
        }
        getPartailLoadLoss(newInputs)
          .then((response) => {
            if (response.data && response?.data !== null) {
              let res = response.data[0];
              let udpData = {} as UserDefinedLoadPoints;
              udpData.data = res;
              udpData.speedUnit = speedUnit;
              udpData.torqueUnit =
                torqueOrPower === 'power' ? 'kW' : torqueUnit;
              udpData.userInputSpeed = speedVal.toString();
              udpData.userInputTorque = torqueVal.toString();
              let udp = [] as UserDefinedLoadPoints[];
              udpPoints.forEach((data, index) => {
                if (index === ind) {
                  udp.push(udpData);
                } else {
                  udp.push(data);
                }
              });
              dispatch(setEfficiencyUDPLossesData(udp));
            } else if (response?.data === null) {
              let updateNullObj = nullObject;
              updateNullObj.speed = newInputs.speed;
              updateNullObj.torque = newInputs.torque;
              let udpData = {} as UserDefinedLoadPoints;
              udpData.data = updateNullObj;
              udpData.speedUnit = speedUnit;
              udpData.torqueUnit =
                torqueOrPower === 'power' ? 'kW' : torqueUnit;
              udpData.userInputSpeed = speedVal.toString();
              udpData.userInputTorque = torqueVal.toString();
              let udp = [] as UserDefinedLoadPoints[];
              udpPoints.forEach((data, index) => {
                if (index === ind) {
                  udp.push(udpData);
                } else {
                  udp.push(data);
                }
              });
              dispatch(setEfficiencyUDPLossesData(udp));
            }
          })
          .catch((err) => {});
      }
    },
    1000,
  );

  const onDelete = (ind: number) => {
    const updatedUdpPoints = udpPoints
      .filter((data, index) => index !== ind) // Remove the item with the given index
      .map((data, index) => ({ ...data, index: index + 1 })); // Reindex the remaining items
    dispatch(setEfficiencyUDPLossesData(updatedUdpPoints));
  };

  const onReset = () => {
    let data = efficiencyData?.efficiencyUDPData;
    dispatch(
      setEfficiencyUdpData({
        ...data,
        speedUnit: '%',
        torqueUnit: '%',
        torqueOrPower: 'torque',
      }),
    );
    dispatch(setEfficiencyUDPLossesData([]));
    props.udpPoints([]);
  };

  let udpData = efficiencyData?.efficiencyUDPData;

  return (
    <div>
      <ColumnSpan
        size="large"
        borderBottom={true}
        collapseIcon={false}
        contentExpanded={true}
        onexpandTextClick={false}
        data-testid="udp-column-span"
      >
        <div slot="label">Add user-defined load points</div>
        <div slot="contentExpanded">
          <div className="speed-torque">
            <div>#</div>
            <div className="disp-flex">
              <div>SPEED</div>
              <div>
                <ButtonGroupCommonux
                  type="primary-black"
                  selectedSegmentedControlId={speedUnit}
                >
                  <ButtonGroupItemCommonux
                    segmentedControlItemId="%"
                    disabled={isLoading}
                    onSegmentedControlItemClick={() => {
                      dispatch(
                        setEfficiencyUdpData({ ...udpData, speedUnit: '%' }),
                      );
                      setUnitChange(true);
                    }}
                    data-rf="percent-view-button"
                  >
                    %
                  </ButtonGroupItemCommonux>
                  <ButtonGroupItemCommonux
                    segmentedControlItemId="rpm"
                    disabled={isLoading}
                    onSegmentedControlItemClick={() => {
                      dispatch(
                        setEfficiencyUdpData({ ...udpData, speedUnit: 'rpm' }),
                      );
                      setUnitChange(true);
                    }}
                    data-rf="rpm-view-button"
                  >
                    rpm
                  </ButtonGroupItemCommonux>
                </ButtonGroupCommonux>
              </div>
            </div>
            <div className="disp-flex">
              <div>
                <ButtonGroupCommonux
                  type="primary-black"
                  selectedSegmentedControlId={torqueOrPower}
                >
                  <ButtonGroupItemCommonux
                    segmentedControlItemId="torque"
                    onSegmentedControlItemClick={() => {
                      dispatch(
                        setEfficiencyUdpData({
                          ...udpData,
                          torqueOrPower: 'torque',
                        }),
                      );
                      setUnitChange(true);
                    }}
                    data-rf="torque-view-button"
                    disabled={isLoading}
                  >
                    Torque
                  </ButtonGroupItemCommonux>
                  <ButtonGroupItemCommonux
                    segmentedControlItemId="power"
                    onSegmentedControlItemClick={() => {
                      dispatch(
                        setEfficiencyUdpData({
                          ...udpData,
                          torqueOrPower: 'power',
                        }),
                      );
                      setUnitChange(true);
                    }}
                    data-rf="power-view-button"
                    disabled={isLoading}
                  >
                    Power
                  </ButtonGroupItemCommonux>
                </ButtonGroupCommonux>
              </div>

              <div>
                {torqueOrPower === 'torque' ? (
                  <ButtonGroupCommonux
                    type="primary-black"
                    selectedSegmentedControlId={torqueUnit}
                  >
                    <ButtonGroupItemCommonux
                      segmentedControlItemId="%"
                      disabled={isLoading}
                      onSegmentedControlItemClick={() => {
                        dispatch(
                          setEfficiencyUdpData({ ...udpData, torqueUnit: '%' }),
                        );
                        setUnitChange(true);
                      }}
                      data-rf="percentage-view-button"
                    >
                      %
                    </ButtonGroupItemCommonux>
                    <ButtonGroupItemCommonux
                      segmentedControlItemId="Nm"
                      disabled={isLoading}
                      onSegmentedControlItemClick={() => {
                        dispatch(
                          setEfficiencyUdpData({
                            ...udpData,
                            torqueUnit: 'Nm',
                          }),
                        );
                        setUnitChange(true);
                      }}
                      data-rf="Nm-view-button"
                    >
                      Nm
                    </ButtonGroupItemCommonux>
                  </ButtonGroupCommonux>
                ) : (
                  <div>
                    <ButtonGroupCommonux
                      type="primary-black"
                      selectedSegmentedControlId={'kW'}
                    >
                      <ButtonGroupItemCommonux
                        segmentedControlItemId="kW"
                        disabled={isLoading}
                        onSegmentedControlItemClick={() => {
                          dispatch(
                            setEfficiencyUdpData({
                              ...udpData,
                              torqueUnit: 'kW',
                            }),
                          );
                          setUnitChange(true);
                        }}
                        data-rf="percentage-view-button"
                      >
                        kW
                      </ButtonGroupItemCommonux>
                    </ButtonGroupCommonux>
                  </div>
                )}
              </div>
            </div>
          </div>
          {udpPoints.map((data: UserDefinedLoadPoints, index) => {
            return (
              <SpeedTorqueSliders
                key={'udp_slider' + index}
                index={index + 1}
                speed={data.userInputSpeed}
                torque={data.userInputTorque}
                speedUnit={speedUnit}
                torqueUnit={torqueUnit}
                disabled={isLoading}
                torqueOrpower={torqueOrPower}
                unitChange={unitChange}
                onSliderValChange={(
                  value: number,
                  type: string,
                  speedVal: number,
                  torqueVal: number,
                  unitchange?: boolean,
                ) =>
                  onSliderValChange(
                    index,
                    value,
                    type,
                    speedVal,
                    torqueVal,
                    speedUnit,
                    torqueUnit,
                    data.data.speed,
                    data.data.torque,
                    unitchange,
                  )
                }
                onDeleteClick={() => {
                  onDelete(index);
                }}
              />
            );
          })}

          <Button
            type="primary-black"
            size="small"
            onClick={onAddClick}
            disabled={udpPoints.length === 8 || isLoading}
            className="udp-add"
            data-testid="udp-add-button"
          >
            <Icon slot="icon" size="small" name={'plus'} theme="light" />
            Add
          </Button>
          <div className="efficiency-note">
            <div className="efficency-info-icon">
              <Icon slot="icon" size="small" name="information-circle-2" />
            </div>
            <div>
              <span className="bold-imp-note">{t(I18.important_note)}</span>
              <br />
              {t(I18.note_for)}{' '}
              <span className="bold-imp-note"> {t(I18.imp_note_udp_tag)}</span>
              {t(I18.imp_note_udp_info)}
              <br />
              {t(I18.note_for)}{' '}
              <span className="bold-imp-note">
                {' '}
                {t(I18.imp_note_standard_point_tag)}
              </span>
              {t(I18.imp_note_standard_point_info)}
            </div>
          </div>
        </div>
        <div slot="icons-container" className="disp-flex">
          <Button
            type="discreet-black"
            onBtnClick={() => {
              onReset();
            }}
            disabled={udpPoints.length === 0}
          >
            <Icon
              slot="icon"
              size="small"
              name={'turn-counter-clockwise'}
              disabled={udpPoints.length === 0}
            />
            <div>Reset</div>
          </Button>

          {/* <Button type="discreet-black">
            <Icon slot="icon" size="small" name={'menu-narrow'} />
          </Button> */}
        </div>
      </ColumnSpan>
    </div>
  );
});

export default UserDefinedLoadPointsSection;
