import useDeviceDetect from '../../hooks/useDeviceDetect';

interface DualSectionLayoutProps {
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  isSidebarOpen: boolean;
  viewResult?: boolean;
}

const DualSectionLayout = ({
  leftChildren,
  rightChildren,
  isSidebarOpen,
  viewResult,
}: DualSectionLayoutProps) => {
  const { isMobile } = useDeviceDetect();

  return (
    <div
      className="dual-section-container"
      data-testid="dual-section-container"
    >
      {!isMobile && (
        <>
          <div
            className={
              isSidebarOpen
                ? 'left-side-container'
                : 'sidebar-collapsed-left-side-container'
            }
            data-testid="dual-section-container-isMobile-left-side"
          >
            {leftChildren}
          </div>
          <div
            className={
              isSidebarOpen
                ? 'right-side-container'
                : 'sidebar-collapsed-right-side-container'
            }
            data-testid="dual-section-container-isMobile-right-side"
          >
            {rightChildren}
          </div>
        </>
      )}
      {!viewResult && isMobile && (
        <div
          className={'left-side-container'}
          data-testid="dual-section-left-side-container"
        >
          {leftChildren}
        </div>
      )}
      {viewResult && isMobile && (
        <div
          className={'right-side-container'}
          data-testid="dual-section-right-side-container"
        >
          {rightChildren}
        </div>
      )}
    </div>
  );
};

export default DualSectionLayout;
