import '../Styles/styles.scss';
import SliderWithInput from './SliderWithInput';
import Icon from '../../../../components/Icon';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { useEffect, useState } from 'react';
import {
  getMaxSpeed,
  getMinSpeed,
  getMaxTorque,
  getMinTorque,
  convertToValue,
  convertToPercentage,
  convertTorqueToPower,
  getMinPower,
  convertPowerToTorque,
  getLoadBalancingTorquePercentage,
  getLoadBalancingSpeed,
} from '../Utils/UdpPointsUnitConversion';
import {
  EfficiencyUDPDetails,
  UserDefinedLoadPoints,
} from '../../models/EfficiencyModel';
import {
  setEfficiencyUdpData,
  setEfficiencyUDPLossesData,
} from '../../store/WorkspaceReducer';
import { store } from '../../../../store/store';

interface SpeedTorqueProps {
  index: number;
  onSliderValChange: (
    value: number,
    type: string,
    speed: number,
    torque: number,
    unitchange?: boolean,
  ) => void;
  onDeleteClick: () => void;
  speed: string;
  torque: string;
  disabled: boolean;
  speedUnit: string;
  torqueUnit: string;
  torqueOrpower: string;
  unitChange: boolean;
}

const SpeedTorqueSliders = (props: SpeedTorqueProps) => {
  const dimensionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      ?.dimensionDetails;
  });

  let overLoad = dimensionData.dimensionInputData.selectionInputs?.[0]
    ?.olBaseInput
    ? parseInt(
        dimensionData.dimensionInputData.selectionInputs?.[0]?.olBaseInput,
      )
    : 100;

  const efficiencyData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank];
  });

  const minSpeedPercent = 25;
  const minSpeedRpm = getMinSpeed(
    dimensionData.motorLoadData,
    dimensionData?.selectMotorResult.dimension.dimensionResult,
  );
  const maxSpeedPercent =
    dimensionData.motorLoadData.loadType === '1' ? 100 : 200;
  const maxSpeedRpm = getMaxSpeed(
    dimensionData.motorLoadData,
    dimensionData?.selectMotorResult.dimension.dimensionResult,
  );
  const minTorquePercent = 15;
  const minTorqueNm = getMinTorque(
    dimensionData?.selectMotorResult.dimension.dimensionResult,
  );
  const maxTorquePercent = overLoad > 150 ? 150 : overLoad;
  const maxTorqueNm = getMaxTorque(
    dimensionData?.selectMotorResult.dimension.dimensionResult,
  );
  const minPower = getMinPower(dimensionData.motorLoadData);
  const maxPower = parseInt(dimensionData.motorLoadData.power);
  const initialTrq = Math.round(convertToValue(45, maxTorqueNm));
  const [currentPower, setCurrentPower] = useState(
    Math.round(
      convertTorqueToPower(
        initialTrq,
        parseInt(dimensionData.motorLoadData.nBase),
      ),
    ),
  );
  const [currentSpeed, setCurrentSpeed] = useState(parseInt(props.speed));
  const [currentTorque, setCurrentTorque] = useState(parseInt(props.torque));
  const dimTorque = parseInt(
    dimensionData?.selectMotorResult?.dimension?.dimensionResult?.supplyDriveLoads?.[0].motorUnit.results.filter(
      (x) => {
        if (x.name === 'Torque ') return x;
      },
    )[0].value,
  );

  const dispatch = useDispatch();
  let udpData: EfficiencyUDPDetails = {
    minSpeedRpm: minSpeedRpm,
    maxSpeedRpm: maxSpeedRpm,
    minSpeedPercentage: minSpeedPercent,
    maxSpeedPercentage: maxSpeedPercent,
    minTorquePercentage: minTorquePercent,
    maxTorquePercentage: maxTorquePercent,
    minTorqueNm: minTorqueNm,
    maxTorqueNm: maxTorqueNm,
    minPower: minPower,
    maxPower: maxPower,
    speedUnit: props.speedUnit,
    torqueUnit: props.torqueUnit,
    torqueOrPower: props.torqueOrpower,
  };

  useEffect(() => {
    dispatch(setEfficiencyUdpData(udpData));
  }, []);

  useEffect(() => {
    setCurrentSpeed(parseInt(props.speed));
    setCurrentTorque(parseInt(props.torque));
    if (props.torqueOrpower === 'power') {
      setCurrentPower(parseInt(props.torque));
    }
  }, [props.speed, props.torque]);

  useEffect(() => {
    if (props.unitChange) {
      if (props.speedUnit === '%' && currentSpeed !== 45) {
        let spd = Math.round(
          convertToPercentage(
            currentSpeed,
            parseInt(dimensionData.motorLoadData.nBase),
          ),
        );
        if (spd >= minSpeedPercent && spd <= maxSpeedPercent) {
          setCurrentSpeed(spd);
          onUnitChange('speed', spd.toString(), '%', props.index);
        } else {
          if (spd < minSpeedPercent) {
            setCurrentSpeed(minSpeedPercent);
            onUnitChange('speed', minSpeedPercent.toString(), '%', props.index);
          }
          if (spd > maxSpeedPercent) {
            setCurrentSpeed(maxSpeedPercent);
            onUnitChange('speed', maxSpeedPercent.toString(), '%', props.index);
          }
        }
      } else if (props.speedUnit === 'rpm') {
        let rpm = Math.round(
          convertToValue(
            currentSpeed,
            parseInt(dimensionData.motorLoadData.nBase),
          ),
        );
        if (rpm >= minSpeedRpm && rpm <= maxSpeedRpm) {
          setCurrentSpeed(rpm);
          onUnitChange('speed', rpm.toString(), 'rpm', props.index);
        } else {
          if (rpm < minSpeedRpm) {
            setCurrentSpeed(minSpeedRpm);
            onUnitChange('speed', minSpeedRpm.toString(), 'rpm', props.index);
          }
          if (rpm > maxSpeedRpm) {
            setCurrentSpeed(maxSpeedRpm);
            onUnitChange('speed', maxSpeedRpm.toString(), 'rpm', props.index);
          }
        }
      }
      udpData = { ...udpData, speedUnit: props.speedUnit };
      dispatch(setEfficiencyUdpData(udpData));
    }
  }, [props.speedUnit, props.unitChange]);

  useEffect(() => {
    if (props.unitChange) {
      if (props.torqueUnit === '%' && currentTorque !== 45) {
        let trq = Math.round(convertToPercentage(currentTorque, maxTorqueNm));
        if (trq >= minTorquePercent && trq <= maxTorquePercent) {
          setCurrentTorque(trq);
          onUnitChange('torque', trq.toString(), '%', props.index);
        }
      } else if (props.torqueUnit === 'Nm') {
        let nmTrq = Math.round(convertToValue(currentTorque, maxTorqueNm));
        if (nmTrq >= minTorqueNm && nmTrq <= maxTorqueNm) {
          setCurrentTorque(nmTrq);
          onUnitChange('torque', nmTrq.toString(), 'Nm', props.index);
        } else {
          if (nmTrq < minTorque) {
            setCurrentTorque(minTorque);
            onUnitChange('torque', minTorque.toString(), 'Nm', props.index);
          }
          if (nmTrq > maxTorque) {
            setCurrentTorque(maxTorque);
            onUnitChange('torque', maxTorque.toString(), 'Nm', props.index);
          }
        }
      }
      udpData = { ...udpData, torqueUnit: props.torqueUnit };
      dispatch(setEfficiencyUdpData(udpData));
    }
  }, [props.torqueUnit, props.unitChange]);

  useEffect(() => {
    if (props.unitChange) {
      if (props.torqueOrpower === 'torque') {
        let powerToTorque = Math.round(
          convertPowerToTorque(
            currentPower,
            parseInt(dimensionData.motorLoadData.nBase),
          ),
        );
        if (props.torqueUnit !== '%') {
          if (powerToTorque >= minTorqueNm && powerToTorque <= maxTorqueNm) {
            setCurrentTorque(powerToTorque);
            onUnitChange('torque', powerToTorque.toString(), 'Nm', props.index);
          }
        } else {
          let trq = Math.round(convertToPercentage(powerToTorque, maxTorqueNm));
          if (trq >= minTorquePercent && trq <= maxTorquePercent) {
            setCurrentTorque(trq);
            onUnitChange('torque', trq.toString(), '%', props.index);
          }
        }
      } else if (props.torqueOrpower === 'power') {
        let power;
        if (props.torqueUnit !== '%') {
          power = Math.round(
            convertTorqueToPower(
              currentTorque,
              parseInt(dimensionData.motorLoadData.nBase),
            ),
          );
          if (power >= minPower && power <= maxPower) {
            setCurrentPower(power);
            onUnitChange('torque', power.toString(), 'kW', props.index);
          }
        } else {
          let trq = Math.round(convertToValue(currentTorque, maxTorqueNm));
          power = Math.round(
            convertTorqueToPower(
              trq,
              parseInt(dimensionData.motorLoadData.nBase),
            ),
          );
          if (power >= minPower && power <= maxPower) {
            setCurrentPower(power);
            onUnitChange('torque', power.toString(), 'kW', props.index);
          }
        }
      }
      udpData = { ...udpData, torqueOrPower: props.torqueOrpower };
      dispatch(setEfficiencyUdpData(udpData));
    }
  }, [props.torqueOrpower, props.unitChange]);

  // to change unit and value of item present in table without making an api call
  const onUnitChange = (
    type: string,
    value: string,
    unit: string,
    ind: number,
  ) => {
    // Use the previous state to ensure you have the latest data
    if (props.unitChange) {
      const thunkFunction = (dispatch: any, getState: any) => {
        const udp: UserDefinedLoadPoints[] =
          efficiencyData?.efficiencyUDPLossesdata ?? [];
        const newUdp = udp.map((data, index) => {
          if (index + 1 === ind) {
            return {
              ...data,
              data: {
                ...data.data,
              },
              speedUnit: type === 'speed' ? unit : data.speedUnit,
              torqueUnit: type === 'torque' ? unit : data.torqueUnit,
              userInputSpeed: type === 'speed' ? value : data.userInputSpeed,
              userInputTorque: type === 'torque' ? value : data.userInputTorque,
            };
          } else {
            return data;
          }
        });
        dispatch(setEfficiencyUDPLossesData(newUdp));
      };
      store.dispatch(thunkFunction);
    }
  };

  const onChange = (val: number, type: string) => {
    if (
      type === 'speed' &&
      parseInt(props.speed) !== val &&
      currentSpeed !== val
    ) {
      setCurrentSpeed(val);
      if (props.speedUnit === '%') {
        let loadBalancingSpeed = getLoadBalancingSpeed(
          dimensionData.motorLoadData,
          dimensionData?.selectMotorResult.dimension.dimensionResult,
          val,
        );
        props.onSliderValChange(loadBalancingSpeed, type, val, currentTorque);
      } else {
        const speedDimOutput =
          dimensionData?.selectMotorResult.dimension.dimensionResult;
        const catalogSpeed =
          speedDimOutput?.supplyDriveLoads[0]?.motorUnit.results.filter((x) => {
            if (x.name === 'Speed ') return x;
          });
        let apiSpeed = Math.round(
          convertToPercentage(val, parseInt(catalogSpeed[0].value)),
        );
        props.onSliderValChange(apiSpeed, type, val, currentTorque);
      }
    }
    if (
      type === 'torque' &&
      parseInt(props.torque) !== val &&
      currentTorque !== val
    ) {
      setCurrentTorque(val);
      if (props.torqueUnit === '%') {
        let LoadBalancingTrq = getLoadBalancingTorquePercentage(
          dimensionData?.selectMotorResult.dimension.dimensionResult,
          val,
        );
        //sending profile balancing torque value
        props.onSliderValChange(LoadBalancingTrq, type, currentSpeed, val);
      } else {
        //sending load profile torque value
        let apiTorque = Math.round(convertToPercentage(val, dimTorque));
        props.onSliderValChange(apiTorque, type, currentSpeed, val);
      }
    }
    if (type === 'power' && currentPower !== val) {
      setCurrentPower(val);
      let trqInNm = Math.round(
        convertPowerToTorque(val, parseInt(dimensionData.motorLoadData.nBase)),
      );
      let trqInPercent = Math.round(convertToPercentage(trqInNm, dimTorque));
      props.onSliderValChange(trqInPercent, 'torque', currentSpeed, val);
    }
  };

  let minTorque = props.torqueUnit === '%' ? minTorquePercent : minTorqueNm;
  let maxTorque = props.torqueUnit === '%' ? maxTorquePercent : maxTorqueNm;
  return (
    <div key={props.index} className="user-defined-points">
      <div>{props.index}</div>
      <div>
        <SliderWithInput
          minValue={props.speedUnit === '%' ? minSpeedPercent : minSpeedRpm}
          maxValue={props.speedUnit === '%' ? maxSpeedPercent : maxSpeedRpm}
          disabled={props.disabled}
          inputValue={currentSpeed}
          unitChange={props.unitChange}
          sliderVal={parseInt(props.speed)}
          unit={props.speedUnit}
          onChange={(e: number) => {
            onChange(e, 'speed');
          }}
        />
      </div>
      <div>
        <SliderWithInput
          minValue={props.torqueOrpower === 'torque' ? minTorque : minPower}
          disabled={props.disabled}
          inputValue={
            props.torqueOrpower === 'torque' ? currentTorque : currentPower
          }
          maxValue={props.torqueOrpower === 'torque' ? maxTorque : maxPower}
          sliderVal={
            props.torqueOrpower === 'torque'
              ? parseInt(props.torque)
              : currentPower
          }
          unit={props.torqueOrpower === 'torque' ? props.torqueUnit : 'kW'}
          unitChange={props.unitChange}
          onChange={(e: number) => {
            if (props.torqueOrpower === 'torque') onChange(e, 'torque');
            else onChange(e, 'power');
          }}
        />
      </div>
      <div
        onClick={() => props.onDeleteClick()}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            props.onDeleteClick();
          }
        }}
        className="cursor"
      >
        <Icon slot="icon" size="small" name={'trash'} className="pds-delete" />
      </div>
    </div>
  );
};

export default SpeedTorqueSliders;
